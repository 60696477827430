import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AuthService } from '#services/shared';
import { Utility } from '#services/shared/utility';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import * as MsalUtils from 'app/msal/utils';
import { MessageService } from 'primeng/api';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [TranslatePipe]
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private authSvc: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private messageService: MessageService,
    private router: Router,
  ) {

    const { PreferredLanguage = 'en' } = this.authSvc.getCurrentUser();

    this.document.documentElement.lang = PreferredLanguage;

    this.subscriptions.add(
      this.translateService.onLangChange.subscribe(({ lang }) => {
        localStorage.setItem('lang', lang);
        this.document.documentElement.lang = lang;
      })
    );
    this.translateService.use(
      PreferredLanguage || localStorage.getItem('lang')
    );

  }
  private subscriptions = new Subscription();
  isFunctionalReviewRoute = false;
  isIframe = MsalUtils.isIframe;

  showDisclaimer: boolean;
  currentUserId: number;

  alertOutdatedAppIsDismissed = false;

  ngOnInit(): void {

    const currentUser = this.authSvc.getCurrentUser();
    this.currentUserId = Utility.isValidObj(currentUser) ? currentUser.ID : 0;
    this.showDisclaimer =
      Utility.isValidObj(currentUser) &&
      !currentUser.DisclaimerAccepted &&
      currentUser.HasAccess;

    this.subscriptions.add(this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.isFunctionalReviewRoute = this.router.url.includes('functional-review');
    }));

    this.alertOutdatedAppIsDismissed = JSON.parse(localStorage.getItem('alerts.outdatedApp.isDismissed'));
  }



  iAccept() {
    this.authSvc.acceptDisclaimer(this.currentUserId);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:beforeunload')
  clearToastrs() {
    this.messageService.clear();
  }

  dismissAlertOutdatedApp() {
    this.alertOutdatedAppIsDismissed = true;
    localStorage.setItem('alerts.outdatedApp.isDismissed', JSON.stringify(true));
  }
}
