import { DictionaryEntries } from './dictionary-entries';

export const ES: DictionaryEntries = {
  '&': 'y',
  'AllBactionitemsmustbeclosed.': 'All B action items must be closed',
  '3rdPartyexternal': '3ra Parte (externo)',
  'ABChecklist': 'Checklist AB',
  'ABChecklistfor': 'Checklist AB para',
  'ACTIONS': 'ACCIONES',
  'AChecklist': 'Checklist A',
  'Action': 'Acción',
  'ActionConfirmation': 'Confirma que desea realizar esta acción?',
  'ActionItemAssignee': 'Delegado de AI',
  'ActionItemisneededtocompletequestion': 'Se requiere una acción para completar la pregunta',
  'ActionItems': 'Items de acción',
  'ActionIsRequired': 'Campo de acción es obligatorio',
  'ActionWillBeTransferred': 'La acción será transferida a operaciones',
  'Actions': 'Acciones',
  'Actionwillbetransferredtooperations': 'El item se transferirá a operaciones',
  'Active': 'Activo',
  'Add': 'Agregar',
  'AddABChecklisttoProject': 'Agregar ChecklistAB al Proyecto',
  'AddABChecktoEndDevice': 'Agregar ChequeoAB al dispositivo final',
  'AddComment': 'Agregar Comentario',
  'AddFeedback': 'Agregar Feedback',
  'AddGroup': 'Agregar Grupo',
  'AddNew': 'AGREGAR NUEVO',
  'AddQuestion': 'Agregar Pregunta',
  'AddRedLining': 'Agregar Red Lining',
  'AddSignature': 'AGREGAR FIRMA',
  'AddTemplate': 'Agregar Plantilla',
  'AddUser': 'Agregar Usuario/a',
  'AddVersion': 'Agregar Versión',
  'AddYellowLining': 'Agregar Yellow Lining',
  'Addactionitem': 'Agregar Item de Acción',
  'AddtoEAM': 'Agregar al EAM',
  'Alert': 'Alerta',
  'Alltherelatedsectionssubsectionsitemswillberemoved': 'Se eliminará(n) seccion(es) | subseccion(es) | item(s) relacionados',
  'Alltherelatedsubsectionsitemswillberemoved': 'Se eliminará(n) subseccion(es) | item(s) relacionados',
  'SendAllNotificationsToApproversInstruction': '*Tildar para que Aprobador Final reciba todas las notificaciones',
  'AllP3actionmustbeclosedtosubmitforapproval': 'Toda accción {{P3Label}} debe quedar cerrada para enviar para aprobación',
  'AllowAIOwnerToAttach': 'Permitir al dueño de la Acción adjuntar',
  'AllowAIOwnerToAttachInstruction': 'Tildar para permitir que el Propietario de la Acción adjunte archivos en cualquier estado de la AI mientras PSSR no esté cerrado',
  'AllowApproverToSubmitForApproval': 'Permitir que Aprobador Final envíe para Aprobación',
  'AllowLeadsDeleteQuestions': 'Permitir que Líderes Borren Preguntas',
  'AllowMembersCompletePSSR': 'Permitir que Miembros Completen PSSR',
  'AllowMembersToCompletePSSR': 'Permitir que Miembros Completen PSSR',
  'AllowMultiApprover': 'Permitir Aprobador Múltiple',
  'AllowNAResponses': 'Permitir Respuestas NA',
  'AllowWetSignature': 'Permitir firma no electrónica en PSSR',
  'Allpages': 'Todas las páginas',
  'Alltherelated': 'Todos los relacionados',
  'Alltherelateditemswillberemoved': 'Todos los item(s) relacionados serán eliminados',
  'AngularTemplatedocumentation': 'Documentación de Plantilla Angular',
  'Answer': 'Respuesta',
  'AnswerallquestionsandsignaturestoCompletethePSSR': 'Responder todas las preguntas y firmas para completar {{PssrTitle}}',
  'AnswerallquestionssignaturestoCompletethePSSR': 'Responder todas las preguntas y firmas para completar {{PssrTitle}}',
  'Answerwillbecleared': 'La respuesta será borrada',
  'Approve': 'Aprobar',
  'Approvebywetsignature': 'Aprobar con firma no electrónica',
  'Approved': 'Aprobado',
  'ApprovedOn': 'Aprobado el',
  'AreyousureyouwanttodeleteABChecklistbelow': 'Confirma que desea borrar este Checklist AB',
  'AreyousureyouwanttodeleteSubCategory': 'Confirma que desea borrar la Sub Categoría',
  'Area': 'Area',
  'Areyousureyouwanttodelete': 'Confirma que desea borrar',
  'AreyousureyouwanttodeleteTaskPlanbelow': 'Confirma que desea borrar Plan de Tareas',
  'AssignedToLeadFinalApprover': 'Asignado al Líder / Aprobador Final',
  'Assignedto': 'Asignado a',
  'Assignedtome': 'Asignado a mí',
  'Assignee': 'Responsable',
  'Assignto': 'Asignar a',
  'Attach': 'Adjuntar',
  'AttachedFiles': 'Archivos adjuntos',
  'AutoLoadImages': 'Cargar imágenes automáticamente',
  'Autoclosems': 'Cerrar automáticamente (ms.)',
  'Available': 'Disponible',
  'BChecklist': 'Checklist B',
  'BUAdmin': 'Admin BU',
  'BUAdmins': 'Administradores de BU',
  'Back': 'Volver',
  'BacktoSubCategoryList': 'Volver a Lista de Sub Categorías',
  'BacktoTaskPlan': 'Volver al Plan de Tareas',
  'BackToList': 'Volver a  lista',
  'Backto': 'Volver a',
  'BacktoABChecksList': 'Volver a lista de Chequeos AB',
  'BacktoAreaList': 'Volver a lista de Áreas',
  'BacktoGroupList': 'Volver a lista de Grupos',
  'BacktoList': 'Volver a lista',
  'BacktoProjectDetail': 'Volver a detalle del Proyecto',
  'BacktoProjectList': 'Volver a lista de Proyectos',
  'BacktoTaskPlanManage': 'Volver a Gestión de Plan de Tareas',
  'Bug': 'Bug',
  'BuildNumber': 'Construir Número',
  'BusinessUnit': 'Unidad de Negocio',
  'BusinessUnitisrequired': 'Requiere Unidad de Negocio',
  'BusinessUnits': 'Unidades de Negocio',
  'By': 'Por',
  'ByDefaultProject': 'Predeterminado \'Proyecto\'',
  'CCCORR': 'PSSR / CCC / ORR',
  'CanOnlyPerformAttachments': 'Se puede realizar Adjuntos',
  'Cancel': 'Cancelar',
  'Canceled': 'Cancelado',
  'CaptureSignature': 'Capturar Firma',
  'Categories': 'Categorías',
  'ChangeRequestApproved': 'Solicitud de Cambio Aprobada',
  'ChangeRequestDenied': 'Solicitud de Cambio Rechazada',
  'Chevron': 'Chevron',
  'ChevronCorporationAllrightsreserved': 'Chevron Corporation. Todos los derechos reservados.',
  'ChevronDirectory': 'Contactos Chevron',
  'ChevronDirectory/SharedUsers': 'Contactos Chevron  / Usuarios Compartidos',
  'ChevronIE': 'I&E Chevron',
  'ChevronSFT': 'SFT Chevron',
  'Clear': 'Limpiar',
  'ClearFilter': 'Limpiar Filtro',
  'ClearQuestion': 'Limpiar Pregunta',
  'ClearSelection': 'Limpiar Selección',
  'Close': 'Cerrar',
  'CloseProjWarning': 'Desea cerrar este proyecto?',
  'CloseProject': 'Cerrar Proyecto',
  'Closed': 'Cerrado',
  'ClosedBy': 'Cerrado por',
  'ClosedOn': 'Cerrado el',
  'Code': 'Código',
  'ComissionDate': 'Fecha de Comisionado',
  'Comment': 'Comentario',
  'Commentgoeshere': 'Comentario aquí',
  'Commentisneededtocompletequestion': 'Se requiere comentario para completar pregunta',
  'CompleteTranslation': 'Completar Traducción',
  'Completed': 'Completado',
  'CompletedQuestions': 'Preguntas Completadas',
  'ConfigureActionItem': 'Configurar AI',
  'Confirm': 'Confirmar',
  'ConfirmDeleteHeader': 'Confirmar Borrar',
  'ConfirmDeleteMessage': 'Va a eliminar este item, desea continuar?',
  'ConfirmDeletePSSRFileHeader': 'Confirmar borrar',
  'ConfirmDeletePSSRFileMessage': 'El Archivo <b>{{fileName}}</b> se eliminará permanentemente, ¿continuar?<br/>',
  'ConfirmPSSRApprovalHeader': '',
  'ConfirmPSSRApprovalMessage': 'Confirma que desea aprobarlo?',
  'ConfirmPSSRReOpenMessage': 'Confirma que desea re-abrir este PSSR?',
  'ConfirmPSSRReOpenHeader': '',
  'ConfirmRejectionHeader': 'Confirmar Rechazo',
  'ConfirmRejectionMessage': 'Si rechaza AI, no podrá aprobar este pssr. Deberá cambiar estado a Recorrida Completa presionando botón <b>{{actionsButton}}\'s</b> al pie de página.',
  'ConfirmTranslationComplete': 'Confirma que están traducidos Título, Descripción, AI y Comentarios?',
  'Confirmdelete': 'Confirmar borrar',
  'Confirmtoproceed': 'Por Favor <b>Confirmar</b> para continuar',
  'ContinueConfirmation': 'Desea continuar?',
  'Contractor': 'Contratista',
  'CopiedImage': 'Imagen <b>{{docName}}</b> copiada',
  'Copy': 'Copiar',
  'CopyEquipmentstoProject': 'Copiar Equipos al Proyecto',
  'CopyImage': 'Copiar Imagen',
  'CopyImageError': 'Error al copiar imagen',
  'CopyfromProject': 'Copiar desde Proyecto',
  'CopyingImage': 'Copiando imagen...',
  'Copyto': 'Copiar a',
  'CostCode': 'Código de Costo',
  'Create': 'Crear',
  'CreateNewItem': 'Crear Nuevo Item',
  'CreateBusinessUnit': 'Crear Unidad de Negocio',
  'CreateGroup': 'Crear Grupo',
  'CreateNew': 'Crear Nuevo',
  'CreateNewSubCategory': 'Crear Nueva Sub Categoría',
  'CreatePSSR': 'Crear {{PssrTitle}}',
  'CreatePSSRCCC': 'Crear PSSR / CCC',
  'CreatePSSRCCCORR': 'Crear PSSR / CCC / ORR',
  'CreatePSSRORR': 'Crear PSSR / ORR',
  'CreateProject': 'Crear Proyecto',
  'CreateSubCategory': 'Crear Sub Categoría',
  'CreateTraining': 'Crear Entrenamiento',
  'CreateUser': 'Crear Usuario',
  'CreatedBy': 'Creado por',
  'CreatedOn': 'Creado el',
  'DBVersion': 'Version DB',
  'Date': 'Fecha',
  'DateCaptured': 'Fecha de Captura',
  'Dates': 'Fechas',
  'Default': 'Predeterminado',
  'DefaultSummarySignatureDisclaimer': 'Descargo predeterminado de firma de Resumen',
  'DefaultWalkdownSignatureDisclaimer': 'Descargo predeterminado de firma de recorrida',
  'Delete': 'Borrar',
  'DeleteActionItem': 'Borrar AI',
  'DeleteDevice': 'Desea Borrar el dispositivo seleccionado',
  'DeleteEndDevice': 'Borrar Dispositivo Final',
  'DeleteEquipment': 'Borrar Equipo',
  'DeleteEquipmentTemplateGroup': 'Borrar Grupo de plantilla de Equipo',
  'DeleteManufacturerModel': 'Borrar Fabricante - Modelo',
  'DeleteProject': 'Esta acción eliminará este proyecto y todos los PSSR relacionados. Desea continuar?',
  'DeleteTaskPlanSection': 'Borrar Sección de Plan de Acción',
  'DeleteTaskPlanSubSection': 'Borrar Sub Sección de Plan de Acción',
  'DeleteselectedEquipmentTemplateGroup': 'Desea borrar el Grupo de plantilla de Equipo seleccionado',
  'Description': 'Descripción',
  'DescriptionScope': 'Descripción y Alcance',
  'Descriptioninputwasalreadyused': 'Datos de Descripción ya utilizados',
  'Descriptionisrequired': 'Se requiere Descripción',
  'DeviceType': 'Tipo de Dispositivo',
  'DeviceTypeEAM': 'Tipo de Dispositivo - Nombre EAM - Descripción EAM',
  'Disabled': 'Deshabilitado',
  'Discard': 'Descartar',
  'DisplayRichEditor': 'Mostrar Editor Completo',
  'Documents': 'Documentos',
  'Download': 'Bajar',
  'DownloadError': 'Error al bajar archivo',
  'DownloadingDocument': 'Bajando documento...',
  'DoyouagreetoDeleteselectedManufacturerModel': 'Desea Borrar Fabricante - Modelo',
  'DoyouagreetoDeleteselectedequipment': 'Desea Borrar el equipo seleccionado',
  'DoyouagreetoRemoveselectedparticipant': 'Desea eliminar el participante seleccionado',
  'Draft': 'Borrador',
  'DraftCreatedOn': 'Borrador creado el',
  'Dropdown': 'Desplegable',
  'DueDate': 'Fecha de vencimiento',
  'RequireActionItemDueDateInstruction': 'Tildar para requerir fecha de vencimiento de AI',
  'EAMClass': 'Clase EAM',
  'EAMCriticality': 'Criticalidad EAM',
  'EAMDepartment': 'Departmento EAM',
  'EAMDescription': 'Descripción EAM ',
  'EAMName': 'Nombre EAM',
  'EAMType': 'Tipo EAM',
  'ENDDEVICESPECIFICABCHECKLISTS': 'CHECKLISTS AB ESPECÍFICOS PARA EL DISPOSITIVO FINAL',
  'EQUIPMENT': 'EQUIPO',
  'Edit': 'Editar',
  'EditBusinessUnit': 'Editar Unidad de Negocio',
  'EditGroup': 'Editar Grupo',
  'EditImageError': 'Editar Error de Imagen',
  'EditOrganization': 'Editar Organización',
  'EditParticipant': 'Editar Participante',
  'EditProject': 'Editar Proyecto',
  'EditRedLining': 'Editar Red Lining',
  'EditSubCategory': 'Editar Sub Categoría',
  'EditTaskPlanSection': 'Editar Sección de Plan de Acción',
  'EditYellowLining': 'Editar Yellow Lining',
  'Email': 'Email',
  'EndDevice': 'Dispositivo Final',
  'EndDeviceList': 'Lista de Dispositivos Finales',
  'EndDeviceTemplate': 'Plantilla de Dispositivos Finales',
  'EndDevices': 'Dispositivos Finales',
  'English': 'Inglés',
  'EnglishTranslationInProgress': 'Traducción al Inglés en Curso',
  'Equipment': 'Equipo',
  'EquipmentGroup': 'Grupo de Equipos',
  'EquipmentList': 'Lista de Equipos',
  'EquipmentTemplate': 'Plantilla de Equipo',
  'EquipmentTemplateGroup': 'Grupo de Plantillas de Equipo',
  'EquipmentTemplateGroups': 'Grupos de Plantillas de Equipo',
  'EquipmentTemplates': 'Plantillas de Equipo',
  'EraseLocalData': 'Borrar información local',
  'ErrorImageEditMessage': 'No fue posible abrir imagen para editar',
  'ErrorImageMessage': 'No fue posible copiar imagen',
  'ErrorImageUndoMessage': 'No fue posible deshacer imagen',
  'EventLabel': 'Etiqueta de nombre de evento',
  'EventName': 'Nombre de Evento',
  'ExistingNonChevronShared': 'No Chevron Existente/Compartido',
  'ExportABChecklistsasPDF': 'Exportar Checklists AB como PDF',
  'ExportExcel': 'Exportar Excel',
  'Facility': 'Instalación',
  'FacilityHierarchy': 'Jerarquía de Instalación',
  'FacilityNo': 'Instalación #',
  'FacilityOperationTeam': 'Equipo de Operación de Instalación',
  'Factory': 'Fábrica',
  'Featurerequest': 'Solicitud de Característica',
  'Feedback': 'Feedback',
  'FeedbackScreen': 'Pantalla de Feedback',
  'Fieldisrequired': 'Campo Obligatorio',
  'FileDuplicate': 'El documento que está tratando de subir ya existe. Presionar el botón Cancelar para cancelar la carga o presionar el botón de Carga para reemplazar el documento existente',
  'Files': 'Archivos',
  'FilesUploaded': 'Archivos cargados con éxito',
  'FilesUploadedWithWarning': 'Algunos archivos se cargaron con éxito. Vuelva a intentar.',
  'FillAndSave': 'Completar los campos marcados obligatorios. Guardar.',
  'Filter': 'Filtro',
  'FilterKMSListbyStatus': 'Filtro:  Lista KMS por Estado',
  'FilterNumberofKMSItemsreturned': 'Filtro: Cantidad de items KMS devueltos',
  'FilteredActionItemsInitialWarning': ' Página filtrada por defecto al usuario actual y estado abierto',
  'FinalApproval': 'Aprobación Final',
  'FinalApprover': 'Aprobador Final',
  'RequireFinalApproverBeforeInitiationInstruction': 'Tildar si desea que PSSR exija inicio por Aprobador Final',
  'FinalApproverPresent': 'Si aprobador figura presente, se puede hacer cierre final en el dispositivo, si no enviar para aprobación y sincronización',
  'FinalApproverSignature': 'Firma Aprobador Final',
  'FoundImages': 'Imágenes encontradas',
  'FullName': 'Nombre Completo',
  'GENERALINFORMATION': 'INFORMACIÓN GENERAL',
  'GeneralInformation': 'Información General',
  'GeneratePSSR': 'Generar {{PSSRLabel}}',
  'GetEAMLoadsheet': 'Obtener hoja de carga EAM',
  'GetEquipmentList': 'Obtener Listado Equipo',
  'GoBack': 'Volver',
  'Group': 'Grupo',
  'GroupName': 'Nombre Grupo',
  'GroupNames': 'Nombres Grupo',
  'UserGroups': 'Grupos de Usuario',
  'Hi': 'Hola',
  'HideCode': 'Ocultar Código',
  'HierarchyLabel1': 'Jerarquía Etiqueta 1',
  'HierarchyLabel2': 'Jerarquía Etiqueta 2',
  'HierarchyLabel3': 'Jerarquía Etiqueta 3',
  'HierarchyLabel3show': 'Jerarquía Etiqueta 3 (mostrar)',
  'I': 'Yo',
  'ID': 'ID',
  'Iagree': 'Acepto',
  'IagreethatallPSSRchecklistitems': 'Acepto que todos los items del checklist de PSSR y todos los items de acción necesarios para una puesta en marcha segura se registraron fielmente',
  'Idontagree': 'No acepto',
  'Image': 'Imagen',
  'ImageCaption': 'Leyenda',
  'ImageCapture': 'Captura de Imagen',
  'Images': 'Imágenes, Esquemas PSI',
  'ImplementationInProgress': 'Implementación en curso',
  'ImplementationInProgressEdit': 'Implementación en curso Editar',
  'ImportSharedUser': 'Importar Usuario Compartido',
  'ImportMOCActions': 'Import MOC Action Items',
  'InProgress': 'En curso',
  'Inactive': 'Inactivo',
  'IncludeProjectTeam': 'Incluir Miembros del Equipo de Proyecto',
  'Info': 'Info',
  'Initiate': 'Iniciar',
  'Initiated': 'Iniciado',
  'InitiatedOn': 'Iniciado',
  'Input': 'Datos a ingresar',
  'InputSharedUserEmail': 'Ingresar email compartido de usuario',
  'InputdescriptionfornewSection': 'Ingresar descripción de nueva Sección',
  'Instructions': 'Instrucciones',
  'InternalError': 'Error interno',
  'IsAdmin': 'Es Admin',
  'IsExternalUser': 'Es Usuario Externo',
  'IsSectionOrderEnabled': 'Habilitar Pedido personalizado para secciones',
  'ItemCode': 'ItemCode',
  'ItemCodeMsg': '- Código de item',
  'ItemDescriptionMsg': '</b><br/>   - Descripción Item',
  'Itemwillbetransferredtooperations': 'Item se transferirá a operaciones',
  'Iteration': 'Repetición',
  'KMSInstruction': 'Buscar por número específico de MOC (izquierda) o seleccionar de la Lista Desplegable de MOC  (derecha)',
  'LOCATION': 'LUGAR',
  'LimitAccessToAIOwner': 'Limitar Acceso al Propietario de AI',
  'LimitAccessToAIOwnerInstruction': 'Tildar  no permitir que propietario/responsable de AI modifique descripción o fecha de vencimiento de due date, or borre AI. ',
  'AllowTeamLeadToAttach': 'Permitir al lider de equipo adjuntar y modificar AI',
  'AllowTeamLeadToAttachInstruction': 'Tildar:  para Líderes después de aprobación pendiente sólo puede adjuntar archivos, delegado, estado y comentario',
  'Links': 'Vínculos ',
  'List': 'Listado',
  'Load': 'Cargar',
  'LoadImages': 'Cargar Imágenes',
  'LoadNewQAQC': 'Cargar nuevos items de QA QC',
  'Loading': 'Cargando',
  'LocaldbID': 'ID db Local',
  'Location': 'Lugar',
  'LogScreen': 'Pantalla de Ingreso',
  'Login': 'Ingresar',
  'LoginExpiresOn': 'Ingreso expira el',
  'LoginProfile': 'Perfil de ingreso',
  'Logout': 'Salir',
  'LookupbyPasscode': 'Buscar por Clave',
  'Loose': 'Suelto',
  'MOC': 'MOC',
  'MOCWO': 'MOC u Orden de Trabajo#',
  'MOCWOProjectNumberRestrictionEnabled': 'Restrición de Número de Proyecto de MOCWO habilitado',
  'Manage': 'Manejar',
  'ManageContentof': 'Manejar Conteido de',
  'ManagesEquipments': 'Maneja Equipos',
  'Mandatory': 'Obligatorio',
  'Manufacturer': 'Fabricante - Modeol',
  'ManufacturerModel': 'Fabricante - Modelo',
  'ManufacturerModelfor': 'Fabricante - Modelo para',
  'MarkasClosed': 'Marcar como cerrado',
  'Message': 'Mensaje',
  'Milestone': 'Hito de QAQC',
  'MilestoneAndWorkOrders': 'Hitos/Pedidos de trabajo QAQC',
  'MilestoneWorkOrder': 'Hitos/WO QAQC',
  'MilestoneWorkOrders': 'Hitos/Pedidos de trabajo QAQC',
  'Milestones': 'Hitos de QAQC',
  'MilestonesWorkOrdersNoavailable': 'No hay hitos/WO disponibles de QAQC',
  'MobileAppVersions': 'Versiones de App Móvil',
  'MobileFeature': 'Característica(s) sólo disponible(s) en móvil',
  'MobilePSSR': 'PSSR móvil',
  'Model': 'Modelo',
  'Multiple': 'Múltiple',
  'MultipleFinalApprovers': 'Tildar para permitir que PSSR tenga múltiples Aprobadores Finales',
  'Mustbecompletedbeforetostartup': 'Debe completarse item antes del inicio',
  'NA': 'N/A',
  'Name': 'Nombre',
  'NewNonChevron': 'Nuevo No Chevron',
  'NewOrganization': 'Nueva Organización',
  'NewPSSR': 'Nuevo PSSR',
  'NewPSSRCCC': 'Nuevo PSSR / CCC',
  'NewPSSRCCCORR': 'Nuevo PSSR / CCC / ORR ',
  'NewPSSRORR': 'Nuevo PSSR / ORR',
  'NewSectionDescription': 'Nueva Descripción de Sección',
  'No': 'No',
  'Nomobileappversionsyet': 'No hay versiones de app móvil aún',
  'NoActionItems': 'No hay AI',
  'Nofeedbackyet': 'No hay feedback aún',
  'Nomilestones': 'No hay hitos/pedidos de trabajo disponibles de QAQC',
  'NonMOC': 'No MOC',
  'NotRegistered': 'No Registrado',
  'NotVisibleMobile': 'Esta sección no está visible aún en móvil hasta que sincronice el usuario',
  'Notauthorized': 'No autorizado',
  'Note': 'Nota',
  'Notfound': 'No hallado',
  'Notifications': 'Notificaciones',
  'OPEN': 'ABRIR',
  'OPSignature': 'Firma OP',
  'ORR': 'ORR',
  'OnHold': 'En espera',
  'OnlyFinalApprovercanApprovePSSR': 'Sólo Aprobador Final puede aprobar {{PssrTitle}}',
  'OnlyPSSRTeamLeadClosePSSR': 'Sólo Líder de Equipo {{PssrTitle}}puede cerrar PSSR.',
  'OnlyPSSRTeamLeadcanClosePSSR': 'Sólo Líder de Equipo PSSR y Aprobador Final pueden Cerrar PSSR',
  'OnlyPSSRTeamLeadcanInitiatePSSR': 'Sólo Líder de Equipo {{PssrTitle}} y Aprobador Final pueden Iniciar PSSR',
  'OnlyPSSRTeamLeadcanSubmitforPendingApproval': 'Sólo Líder de Equipo {{PssrTitle}} puede Enviar para Aprobación Pendiente.',
  'OnlyTeamLeadorSectionLeadcananswerthequestions': 'Sólo Líder de Equipo o Líder de Sección puede responder a las preguntas.',
  'Open': 'Abrir',
  'OpenStatus': 'Abierto',
  'OpenError': 'Error al Abrir Archivo',
  'OpenErrorMsg': 'No fue posible abrir documento',
  'OpeningDialog': 'Abriendo diálogo',
  'OpeningImage': 'Abriendo imagen...',
  'OperationTeam': 'Equipo de Operación',
  'OperationsRepresentative': 'Representante de Operaciones',
  'Optional': 'Opcional',
  'OrgLabel': 'Etiqueta Org',
  'Organization': 'Organización',
  'OrganizationAdmin': 'Admin de Organización',
  'OrganizationLabel': 'Etiqueta de Organización',
  'Organizationisrequired': 'Se requiere Organización',
  'Other': 'Otros',
  'OtherOptions': 'Otras Opciones',
  'OutstandingQuestions': 'Preguntas pendientes',
  'P3ActionItemsMustBeClosed': 'Se deben cerrar los AI {{P3Label}}.',
  'P3Label': 'Etiqueta P3',
  'P4ActionItemsMustBeClosed': 'Se deben cerrar los AI {{P4Label}}.',
  'P4Label': 'Etiqueta P4',
  'P5Label': 'Etiqueta P5',
  'PARTICIPANTS': 'PARTICIPANTES',
  'PDFPreview': 'Vista previa de PDF',
  'PIDVerificationRedLining': 'Verificación P&ID (Red Lining)',
  'PIDVerificationYellowLining': 'Verificación P&ID (Yellow Lining)',
  'PROJECTSPECIFICABCHECKLISTS': 'CHECKLISTS AB ESPECÍFICOS DEL PROYECTO',
  'PROJECTSTATUS': 'ESTADO DEL PROYECTO',
  'PSSR': 'PSSR',
  'PSSRID': 'PSSR ID',
  'PSSRCCC': 'PSSR / CCC',
  'PSSRInitiatorGroup': 'Grupo Iniciador de PSSR',
  'PSSRLabel': 'Etiqueta PSSR',
  'PSSRMembers': 'Miembros de {{PssrTitle}}',
  'PSSRORR': 'PSSR / ORR',
  'PSSRSections': 'Secciones de PSSR',
  'PSSRStatus': 'Estado de {{PssrTitle}}',
  'PSSRTeam': 'Equipo de {{PssrTitle}}',
  'PSSRTeamLead': 'Líder de Equipo {{PssrTitle}}',
  'PSSRTemplates': 'Plantilla de PSSR',
  'PSSRTemplateisrequired': 'Se requiere Plantilla de PSSR',
  'PSSRTitle': 'Título de PSSR',
  'PSSRType': 'Tipo de PSSR',
  'PSSRTypes': 'Tipos de PSSR',
  'PSSRmusthaveaFinalApprovertocompleteWalkthrough': '{{PssrTitle}} debe tener Aprobador Final Approver para completar Recorrido',
  'PSSRs': 'PSSRs',
  'NavigateToMainPage': '{{PssrTitle}}s',
  'Page': 'Página',
  'Search': 'Búsqueda',
  'Participants': 'Participantes',
  'ParticipantsinBlueparticipatedinWalkdown': 'Participantes de Blue participaron de la Recorrida',
  'ParticipatedinWalkdown': 'Participó en Recorrida',
  'PendingApproval': 'Pendiente de Aprobación',
  'PendingApprovalSubmittedOn': 'Pendiente de Aprobación presentado el',
  'Photos': 'Fotos',
  'PickError': 'Identificar Error de Archivo',
  'PleaseNotify': 'Notificar a participantes para sincronizar los dispositivos móviles antes de continuar con sus actividades',
  'PleaseclickSave': 'Cliquear Guardar',
  'Pleasecorrectpageaddress': 'Corregir dirección de página o soporte de contacto si cree que este url es correcto',
  'Pleasefillupthefields': 'Completar los campos',
  'Pleasefillupthefieldsthataremarkedrequired': 'Completar los campos marcados como obligatorios',
  'PleasegotoSettings': 'Ir a \'Configuración - Notificaciones - PSSR Móvil\' para Permitir notificación',
  'Pleaseselectanyoption': 'Seleccionar cualquier opción que aplique al alcance del cambio y la plantilla',
  'Pleaseselectanyoptionthatapplytothescopeofchangeandtemplate': 'Seleccionar cualquier opción que aplique  al alcance del cambio y la plantilla',
  'Post-WalkdownActionItems': 'AI posteriores a la Recorrida',
  'PostWalkdown': 'Posterior a la Recorrida',
  'PreStartupSafetyReview': 'Revisión de Seguridad Previa al Inicio',
  'Present': 'Presente',
  'PrimaryCategory': 'Categoría Primaria',
  'Print': 'Imprimir',
  'PrinttoPDF': 'Imprimir a PDF',
  'Priority': 'Prioridad',
  'ProcessActivated': 'Proceso Activado',
  'Progress': 'Avance',
  'Project': 'Proyecto',
  'ProjectName': 'Nombre del Proyecto',
  'ProjectTeamWillComplete': 'A completar por el equipo de Proyecto después del inicio',
  'Projects': 'Proyectos',
  'ProjectsList': 'Listado de Proyectos',
  'Projectteamwillcompleteafterstartup': 'Se completará el Item después del inicio',
  'ProvidedBy': 'Proporcionado por',
  'PullFromKMS': 'Tomar de KMS',
  'PushNotification': 'Notificación de Push',
  'Question': 'Pregunta',
  'RESET': 'RESETEAR',
  'ReOpen': 'Reabrir',
  'ReadyToStartUp': 'Listo para Iniciar',
  'ReceivePushNotification': 'Recibir Notificación de Push',
  'Receivedfrom': 'Recibido de',
  'Recordwillbepermanentlyremovedcontinue': 'Se eliminará permanentement el registro, desea continuar?<br/>',
  'RedirectURL': 'Redireccionar URL',
  'ReferenceImage': 'Imagen de Referencia',
  'Reject': 'Rechazar',
  'Rejectionreason': 'Razón de rechazo',
  'ReleaseDate': 'Fecha de lanzamiento',
  'Remove': 'Eliminar',
  'RemoveLocalData': 'Eliminar Datos Locales',
  'RemoveLog': 'Eliminar Registro',
  'RemoveParticipant': 'Eliminar Participante',
  'RemoveRedLining': 'Eliminar Red Lining',
  'RemoveSection': 'Eliminar Sección',
  'RemoveSignature': 'Eliminar Firma',
  'RemoveYellowLining': 'Eliminar Yellow Lining',
  'RemovingWarning': ' <u>Se eliminarán</u> <i>Items de acción</i>, <i>Comentarios</i> e <i>Imágenes</i> de esta pregunta',
  'Reopen': 'Reabrir',
  'RequestAccess': 'Solicitar Acceso',
  'RequestTranslation': 'Solicitar Traducción',
  'RequestedBy': 'Solicitado por',
  'RequestedOn': 'Solicitado el',
  'Requestedby': 'Solicitado por',
  'RequireActionItemDueDate': 'Requiere Fecha de vencimiento del Item de Acción',
  'RequireFinalApproverBeforeInitiation': 'Requiere Aprobador Final antes del Inicio',
  'RequiredField': 'Este campo es obligatorio.',
  'RequiresApprovalForAccess': 'Requiere Aprobación para el Acceso',
  'RequiresShowProject': '*Requiere Mostrar Proyecto para habilitar.',
  'ResetABChecklistsforEntireProject': 'Resetear Checklists AB para todo el Proyecto',
  'ResetABChecklist': 'Resetear Checklist AB',
  'ResetData': 'Resetear Datos',
  'ResetSession': 'Resetear Sesión',
  'ResolutionImages': ' Resolución de Imágenes',
  'RestorePssr': 'Restaurar {{PssrTitle}}',
  'RestrictApproverSignatureOnLeadsDevice': 'Restringir Firma de Aprobador en Dispositivo Líder',
  'ReturnTeamLead': 'Devolver al Líder del Equipo',
  'ReviewInProgress': 'Revisión en curso',
  'Role': 'Rol',
  'RolesChangeWarning': 'Se reflejarán los roles y cambios después de sincronizar',
  'Russian': 'ruso',
  'RussianTranslationRequested': 'Se solicta traducción de ruso',
  'SAVESIGNATURE': 'GUARDAR FIRMA',
  'Save': 'Guardar',
  'SaveChanges': 'Guardar cambios',
  'SaveGoBack': 'Guardar y volver',
  'SaveImage': 'Guardar Imagen',
  'SaveSignature': 'Guardar Firma',
  'SavingFile': 'Guardando Archivo',
  'SavingImage': 'Guardando imagen',
  'ScheduledEmail': 'Desencadenar Email programado (todos los lunes)',
  'Screen': 'Pantalla',
  'SearchMOCNumber': 'Buscar Número de MOC',
  'SecondaryCategory': 'Categoría Secundaria',
  'SectionType': 'Tipo de Sección',
  'SectionLead': 'Líder de Sección',
  'Sections': 'Secciones',
  'ShowSectionsCollapsedInstruction': '*Tildar para mostrar colapsadas las secciones de PSSR al editar',
  'Select': 'Seleccionar',
  'SelectFileError': 'Seleccionar Error de Archivo',
  'SelectFromKMSMOCList': 'Seleccionar del Listado de KMS MOC List',
  'SelectLead': 'Seleccionar Líder',
  'SelectOption': 'Seleccionar Opción',
  'SelectParticipant': 'Seleccionar Participante',
  'SelectResponse': 'Seleccionar Respuesta',
  'SelectaDrawing': 'Seleccionar un Esquema',
  'SelectfromQAQC': 'Seleccionar de QAQC',
  'Selectpages': 'Seleccionar páginas',
  'Selectthepagestoconverttoimages': 'Seleccionar la página a convertir en imágenes',
  'Selectuserfor': 'Seleccionar usuario para',
  'SelectUser': 'Seleccionar usuario',
  'Send': 'Enviar',
  'SendAllNotificationsToApprovers': 'Aprobadores reciben todas las notificaciones de pssr',
  'SendEmailReminder': 'Enviar Recordatorio de Email',
  'SendMOCforStartup': 'Send to MOC record for startup approval',
  'SerialNumber': 'Número de Serie',
  'SerialNumberRequired': 'Se requiere Número de Serie',
  'Settings': 'Configuración',
  'Shared': 'Compartido',
  'Shipment': 'Envío',
  'ShouldAddCommentToQuestion': 'Requiere Comentario para Pregunta NA',
  'Show': 'Mostrar',
  'ShowCCC': 'Mostrar CCC',
  'ShowDeletedPSSR': 'Mostrar {{PssrTitle}} eliminado',
  'ShowKMS': 'Mostrar KMS',
  'ShowORR': 'Mostrar ORR',
  'ShowP5': 'Mostrar P5',
  'ShowProject': 'Mostrar Proyecto',
  'ShowProjectInput': 'Mostrar Datos de Proyecto',
  'ShowProjectInputAsDropdown': 'Mostrar Datos del Proyecto como Desplegable',
  'ShowPssrType': 'Mostrar Tipo de PSSR',
  'ShowQAQC': 'Mostrar QAQC',
  'ShowSectionsCollapsed': 'Mostrar Secciones Colapsadas',
  'ShowVerbalApproval': 'Mostrar Aprobación Verbal',
  'SignatoryName': 'Nombre firmante',
  'Signature': 'Firma',
  'SignatureCapture': 'Captura de Firma',
  'Signatures': 'Firmas',
  'SignedBy': 'Firmado por',
  'SignedOnBehalfOf': 'Firmado en nombre de',
  'Skidded': 'Montado en patines',
  'SortBy': 'Clasificar por',
  'SortChangesApplied': 'Los cambios de clasificación se aplicarán después de pulsar botón Guardar',
  'SortOrder': 'Orden de Clasificación de EAM',
  'Startaddingparticipants': 'Comenzar a Agregar participantes',
  'Started': 'Iniciado',
  'Status': 'Estado',
  'StatusIsRequired': 'Campo de estado es obligatorio',
  'SubArea': 'SubArea',
  'SubCategories': 'Sub Categorías',
  'SubSection': 'Sub Sección',
  'Subcategories': 'Subcategorías',
  'Submit': 'Enviar',
  'SubmitforApproval': 'Enviar para Aprobación',
  'Submitforapproval': 'Enviar para Aprobación',
  'SubsectionDescription': 'Descripción de Subsección',
  'Summary': 'Resumen',
  'SummarySignature': 'Firma del Resumen',
  'Sync': 'Sincronizar',
  'SystemAdministrators': 'Administradores del Sistema',
  'TEAMMEMBERS': 'MIEMBROS DEL EQUIPO',
  'Tag': 'Rótulo',
  'TagCode': 'Código de rótulo',
  'TagNo': 'Número de rótulo',
  'Takeaphoto': 'Tomar foto',
  'Tank': 'Tanque',
  'TaskPlanSections': 'Secciones de Plan de Tareas',
  'TaskPlan': 'Plan de Tareas',
  'TaskPlanNoteHeader': '[Nota]&nbsp; el Nombre del Código debe ser único y no puede ser cambiado después de crear el Plan de Tareas',
  'TeamLead': 'Líder de Equipo',
  'Template': 'Plantilla',
  'Test': 'Prueba',
  'TestforDeleteDBwithoutApprover': 'Probar Borrar DB sin Aprobador',
  'TheTaskPlanfieldisrequired': 'Se requiere campo de Plan de Tareas',
  'Thereareexistingitemsusingit': 'Utilizado por items existentes',
  'Therewasanerrorprocessingtryagain': 'Error al procesar su solicitud. Volver a intentar más tarde o comunicarse con mesa de ayuda.',
  'Thisfieldisrequired': 'Este campo es obligatorio.',
  'Thisisnotareversiblechange': 'No es un cambio reversible',
  'TimeCaptured': 'Hora de Captura',
  'Title': 'Título',
  'Titleisrequired': 'Se requiere Título',
  'TotalQuestions': 'Total Preguntas',
  'TranslationCompleted': 'Traducciónn Completada',
  'TranslationHistory': 'Historial de Traducción',
  'TranslationInProgress': 'Traducción en curso',
  'TranslationRequested': 'Traducción solicitada',
  'Translator': 'Traductor',
  'Type': 'Tipo',
  'TypeOtherisonlyfornon': '"Otros" es sólo para usuarios no Chevron, el uso de este tipo puede duplicar los datos del usuario ',
  'Undo': 'Deshacer',
  'UndoImageError': 'Deshacer Error de Imagen',
  'Unit': 'Unidad',
  'Update': 'Actualizar',
  'UpdatedBy': 'Actualizado por',
  'UpdatedByUpdatedDate': 'Actualizado por/ Fecha de actualización',
  'UpdatedDate': 'Fecha de actualización',
  'Upload': 'Cargar',
  'UploadDocError': 'Cargar Error de Documento',
  'UploadDocErrorMsg': 'No se pudo guardar el documento',
  'UploadDocument': 'Cargar Documento',
  'UploadDocuments': 'Cargar Documentos',
  'UploadImages': 'Cargar Imágenes',
  'UploadMsg': 'Nuevo documento guardado',
  'Uploadaphoto': 'Cargar una foto',
  'User': 'Usuario',
  'User1': 'Usuario 1',
  'User2': 'Usuario 2',
  'User3': 'Usuario 3',
  'User4': 'Usuario 4',
  'UserAlreadyAdded': 'Usuario ya agregado',
  'UserType': 'Tipo de Usuario',
  'Users': 'Usuarios',
  'Usersavedsuccessfully': 'Usuario guardado con éxito',
  'Vendor': 'Proveedor',
  'VerbalApproval': 'Aprobación Verbal',
  'VerbalApprovalReceivedBy': 'Aprobación Verbal recibida por',
  'VerbalApprovalforStartupOn': 'Aprobación Verbal para Inicio el',
  'Version': 'Versión',
  'ViewImage': 'Ver Imagen',
  'ViewItems': 'Ver Items',
  'WO': 'WO',
  'WTNotPresentParticipantsWarning': 'Los siguientes participantes no fueron Seleccionados al presente',
  'WTParticipantsWithoutSignatureWarning': 'Participante(s) sin firma',
  'WTRestrictParticipantsModificationWarning': 'After Complete Recorrida participants wont be modifiable',
  'Walkdown': 'Recorrida',
  'WalkdownSignature': 'Firma de Recorrida',
  'WalkdownComplete': 'Recorrida completa',
  'WalkdownCompletedOn': 'Recorrida Completada el',
  'Warning': 'Advertencia',
  'WebLink': 'Vínculo Web',
  'WorkOrder': 'Pedido de trabajo QAQC',
  'WorkOrders': 'Pedidos de trabajo QAQC',
  'WorkOrdersNoavailable': 'No hay hitos/pedidos de trabajo disponibles de QAQC',
  'XCoordinate': 'Coordenada X',
  'YCoordinate': 'Coordenada Y',
  'Yes': 'Sí',
  'Youareabouttologout': 'Está por salir, esto eliminará los datos locales de este dispositivo, desea continuar?',
  'Youareabouttorequestaccessfor': 'Está por solicitar acceso a  <strong>{{Name}}</strong>, desea continuar?',
  'Youareloggedinas': 'Está conectado como',
  'Youdonothaveaccesstothispage': 'No tiene acceso a eta página',
  'Yourprofile': 'Su perfil',
  'approvethisPSSRreadyforstartup': 'aprobar este PSSR listo para puesta en marcha',
  'cannotberemoved': 'no se puede eliminar',
  'cost255': 'Código de Costos (máx 255 caracteres)',
  'desc5000': 'Agregar descripción aquí (máx 5000 caracteres)',
  'description1920': 'Agregar descripción aquí (máx 1920 caracteres)',
  'description500': 'Agregar descripción aquí (máx 500 caracteres)',
  'device150': 'Nombre tipo de dispositivo (máx 150 caracteres)',
  'donothaveaccesstothePSSRapplication': 'sin acceso a la aplicación PSSR',
  'eam50': 'Tipo de EAM (máx 50 caracteres)',
  'eamclass80': 'Clase de EAM (máx 80 caracteres)',
  'eamcritical50': 'Criticalidad de EAM (máx 50 caracteres)',
  'eamdep255': 'Departamento de EAM (máx 255 caracteres)',
  'eamdesc80': 'Descripción de EAM (máx 80 caracteres)',
  'eamname80': 'Nombre de EAM (máx 80 caracteres)',
  'fromTaskPlan': 'del Plan de Tareas',
  'fromTaskPlanSection': 'de la Sección del Plan de Tareas',
  'group155': 'Nombre de Grupo (máx 155 caracteres)',
  'isrequired': 'es obligatorio',
  'list': 'listado',
  'manufacturer250': 'Nombre del Fabricante (máx 250 caracteres)',
  'model250': 'Modelo (máx 250 caracteres)',
  'noticetousers': 'Aviso a Usuarios',
  'of10': 'de 10',
  'of150': 'de 150',
  'of155': 'de 155',
  'of250': 'de 250',
  'of255': 'de 255',
  'of50': 'de 50',
  'of500': 'de 500',
  'of80': 'de 80',
  'optional': 'opcional',
  'select1milestone': 'Seleccionar 1 hito para visualizar órdenes de trabajo dentro del hito',
  'tagcode10': 'Código de rótulo (máx 10 caracteres)',
  'tagno10': 'N° de rótulo (máx 10 caracteres)',
  'typeemail': 'tipear email de nuevo usuario...',
  'typelastname': 'tipear apellido de nuevo usuario...',
  'typenewuser': 'tipear nombre nuevo usuario...',
  'typeusercainame': 'tipear CAI, nombre o email de Usuario de Chevron...',
  'willberemoved': 'será eliminado',
  'willbeungrouped': 'Si existen equipos unidos a este Grupo, estos se desagruparán',
  'x255': 'Coordenada X (máx 255 caracteres)',
  'y255': 'Coordenada Y (máx 255 caracteres)',
  'yourAccessRequestisinProgress': 'so Solicitud de Acceso está en Curso',
  'youwanttoproceed': 'desea continuar',
  'Unabletocompleteoperation': 'No se pudo completar la operación',
  'SaveCompleted': 'Se completó Guardar',
  'Itemhasbeenaddedsuccessfully': 'Se agregó con éxito el Item',
  'ShowProjectInputInstruction': '*Visualiza desplegable/datos del Proyecto',
  'ShowQAQCInstruction': '*Habilita Módulo QAQC en el módulo del Proyecto. Requiere habilitar primero el Proyecto.',
  'ShowKMSInstruction': '*Visualiza botón "Pull from KMS" cuando se crea un nuevo PSSR',
  'AllowWetSignatureInstruction': '*Permite que Representante de Operaciones apruebe PSSR en estado "Pendiente de aprobación". Visualiza botón "Aprobar con firma no electrónica"',
  'ShowVerbalApprovalInstruction': '*Visualizar una entrada en el App Móvil para escribir manualmente quién aprueba. Esto no causa ninguna acción. Puede ser realizado por Líder o Aprobador',
  'ScheduledEmailInstruction': '*Each Monday at 8:00 am CST an email is triggered for all the Usuarios with pending action items',
  'ShowCCCInstruction': '*Visualiza "CCC" at some headers/titles/labels (Construction Complete Certificate)',
  'ShowORRInstruction': '*Visualiza "ORR" at some headers/titles/labels (Operation Readines Review)',
  'ShowPssrTypeInstruction': '*Visualiza "PSSR Types" dropdown (below)',
  'AllowNAResponsesInstruction': '*If Disabled:  a question at PSSR that is answered as NA would require a comment or action item to be considered as completed.',
  'PssrInstructionsInstruction': '*Visualiza general instructions for the PSSR',
  'AllowLeadsDeleteQuestionsInstruction': '*Permite que los Líderes supriman preguntas para un PSSR en estado Borrado o Iniciado.',
  'EnableAllowMembersCompletePSSRInstruction': '*Si tildado permite que cualquier miembro del equipo responda preguntas, firme preguntas y cree items de acción hasta la aprobación',
  'MOCWOProjectNumberRestrictionEnabledInstruction': '*Si Habilitado:  MOC y WO deben crear PSSR',
  'AllowMultiApproverInstruction': '*Si Habilitado:  PSSR puede tener más de un Aprobador',
  'RestrictApproverSignatureOnLeadsDeviceInstruction': '*Si Habilitado:  Líder de PSSR no puede firmar en nombre del Aprobador en App Móvil',
  'AllowLeadtoShareDeviceWithApprover': 'Allow Lead to Share Device With Approver',
  'AllowLeadtoShareDeviceWithApproverInstruction': '*If Enabled: The Lead can share the device with the Approver if the Approver is present to sign.',
  'RequiresApprovalForAccessInstruction': '*Si está Habilitado este indicador el Usuario deberá esperar hasta que el Admin de la BU le dé acceso. Si inhabilitado, Usuario tendrá acceso instantáneo seleccionando esta BU',
  'AllowApproverToSubmitForApprovalInstruction': '*If Checked Final Approval will be able to see Submit for Approval button in PSSR',
  'OrganizationCannotBeEmpty': '{{OrganizationLabel}} no puede estar vacío',
  'OrganizationSuccessfullyAdded': '{{OrganizationLabel}} agregado con éxito',
  'MOCImportedSuccessfully': 'MOC Importado con éxito',
  'GeneralInformationSavedSuccessfully': 'Información General guardado con éxito',
  'Therecordenteredalreadyexists': 'El registro ingresado ya existe ',
  'SelectuserforTranslationRole': 'Seleccionar usuario para Rol de Traducción',
  'TranslatetoLanguage': 'Traducir a Idioma',
  'Selecttranslator': 'Seleccionar traductor',
  'PresentParticipantsInstruction': 'Participantes en<span class="box participated">&nbsp;Blue&nbsp;</span>participaron de Recorrida',
  'PssrParticipantAddedSuccessMessage': 'Usuario agregado con éxito a participantes de PSSR',
  'RoleAlreadyAddedWarningMessage': 'Rol de {{RoleLabel}} ya fue agregado',
  'MaxParticipantsPerBatchInstruction': 'Sólo se pueden agregar 5 usuarios a la vez',
  'MaxParticipantsPerBatchWarningMessage': 'Se alcanzó el límite de 5 usuarios, seleccionar agregar',
  'ParticipantAlreadyParticipatedWarningMessage': 'Este usuario ya es miembro de este pssr',
  'ManageSectionUsersSyncWarning': 'Esta sección aún no está visible en el móvil hasta que sincronice el usuario. Notificar a los participants para sincronizar sus dispositivos móviles antes de continuar con sus actividades.',
  'NotFoundComponentTitle': 'No hallado',
  'NotFoundComponentDescription': 'Corregir dirección de página o soporte de contacto si cree que este url es correcto',
  'UnauthorizedTitle': 'No autorizado',
  'UnauthorizedDescription': 'Usted no tiene acceso a esta página.',
  'BUisrequired': 'BU es obligatorio',
  'SectionLeadWasAlreadyAddedWarningMessage': '{{UserName}} ya es líder de esta sección',
  'SectionLeadAddedErrorMessage': 'Ocurrió un error al agregar usuario',
  'SectionLeadRemovedErrorMessage': 'Ocurrió un error al eliminar usuario',
  'AddResponseErrorMessage': 'Ocurrió un error al crear la pregunta de respuesta',
  'ConfirmRemoveSectionTitle': 'Eliminar Sección',
  'ConfirmRemoveSectionDescription': 'Confirma que desea realizar esta acción?',
  'MilestoneWorkOrdersWarningMessage': ' Seleccionar 1 hito para visualizar órdenes de trabajo dentro de ese hito',
  'QAQCAddSectionLeadToPssr': 'Asignar líderes de sección',
  'LoadBusinessUnitsErrorMessage': 'Error al obtener Unidades de Negocio',
  'LoadOrganizationsErrorMessage': 'Error al obtener Organizaciones',
  'AddProjectErrorMessage': 'Error al agregar nuevo {{EventLabel}}',
  'AddProjectSuccessMessage': 'Proyecto agregado con éxito',
  'LoadQAQCErrorMessage': 'Error al obtener {{EventLabel}} de QAQC',
  'SaveQAQCAlreadyExistsWarningMessage': '{{EventLabel}} ya existe',
  'SaveQAQCSuccessMessage': '{{EventLabel}} agregado con éxito',
  'CompletedTotalPSSRs': 'PSSRs completados/totales',
  'ProjectUpdatedSucessfully': 'Proyecto actualizado con éxito',
  'ConfirmDeleteProjectTitle': 'Confirmar Eliminación del Proyecto',
  'ConfirmDeleteProjectWithPssrsDescription': 'La siguiente acción podría borrar el Proyecto y los PSSRs involucrados. Desea continuar?',
  'ConfirmDeleteProjectWithoutPssrsDescription': 'Desea continuar?',
  'ConfirmInitiateProjectPssrsTitle': 'Desea iniciar todos los PSSRs?',
  'ConfirmInitiateProjectPssrsDescription': 'Desea iniciar todos los PSSRs?',
  'UnableLoadQAQCMilestonesMessage': 'No se pudo obtener el sistema qaqc para ver hitos, intente nuevamente',
  'UnableLoadQAQCWorkOrdersMessage': 'No se pudo obtener el sistema qaqc para ver pedidos de trabajo, intente nuevamente',
  'LoadProjectDetailsErrorMessage': 'Error al cargar los detalles del proyecto',
  'QAQCItemsAreUpToDateMessage': 'Items QAQC actualizados',
  'QAQCNewItemInputWarning': 'Seleccionar un item del listado de {{qaqcLabel}} y seleccionar los valores de jerarquía y/o plantilla',
  'ConfirmCloseProjectTitle': 'Cerrar Proyecto',
  'ConfirmCloseProjectDescription': 'Desea cerrar este proyecto?',
  'DownloadActionItemsExcelReportFailureMessage': 'No se descargó el archivo de Excel',
  'ConfirmDeleteAreaTitle': 'Confirma que desea borrar {{hierarchyLabel1}}',
  'ConfirmDeleteAreaDescription': 'Se eliminarán todos los {{ hierarchyLabel2 }} y {{ hierarchyLabel3 }} relacionados.',
  'ConfirmDeleteSubAreaTitle': 'Confirma que desea borrar {{ hierarchyLabel2 }}',
  'ConfirmDeleteSubAreaDescription': 'Se eliminarán todos los {{ hierarchyLabel3 }} relacionados',
  'NewGroupadded': 'Se agregó nuevo grupo',
  'RequiredFields': 'Campos obligatorios',
  'AddedUserSuccessfulMessage': 'Se agregó usuario con éxito',
  'DeletedUserSuccessfulMessage': 'Se eliminó usuario con éxito',
  'EditedGroupSucessfulMessage': 'Se actualizó grupo con éxito',
  'Userisrequired': 'Se requiere usuario',
  'FirstNameisrequired': 'Se requiere nombre',
  'SharedUserNameisrequired': 'Se requiere Nombre de Usuario Compartido',
  'Emailisrequired': 'Se requiere email',
  'User1isrequired': 'Se requiere Usuario 1',
  'Usertypeiswrong': 'Tipo de usuario equivocado',
  'RepeatedEmailsInputWarning': 'Estos emails están repetidos:  [{{repeatedEmails}}}]<br/>',
  'Shareduserfound': 'Se encontró usuario compartido',
  'AddRemoveSubcategory': 'Agregar/Eliminar Subcategoría',
  'CreateNewCategory': 'Crear Nueva Categoría',
  'IsActive': 'Está Activo',
  'EditCategory': 'Editar Categoría',
  'CommentisRequired': 'Se requiere comentario',
  'RejectreasonisRequired': 'Se requiere razón del rechazo<br>',
  'Duedateisrequired': 'Se requiere fecha de vencimiento',
  'IAgree': 'Acepto',
  'All': 'Todo(s)',
  'CreateFeedback': 'Crear Feedback',
  'Enhancement': 'Mejora',
  'Environment': 'Entorno',
  'Web': 'Web',
  'MobileWeb': 'Móvil y Web',
  'Mobile': 'Móvil',
  'High': 'Alto',
  'Medium': 'Medio',
  'Low': 'Bajo',
  'FeedbackSuccessfullyadded': 'Se agregó feedback con éxito',
  'EditFeedback': 'Editar Feedback',
  'Typetoaddanewdiscussioncomment': 'Tipear para Agregar un nuevo comentario de debate',
  'Attachments': 'Adjuntoss',
  'ADOStatus': 'Estado ADO',
  'FeedbackUpdated': 'Feedback Actualizado',
  'UpdatedFeedbacksAttachmentErrorMessage': 'Se produjo un error con los archivos. Constatar que los archivos no estén corrompidos o sean demasiado grandes',
  'LoadingKMSItemsErrorMessage': 'Se produjo un error al buscar items KMS',
  'TaskPlanManageTitle': 'Administrar Contenido de {{ Description }}',
  'CreateNewSection': 'Crear Nueva Sección',
  'NewSubsection': 'Nueva Subsección',
  'ItemType': 'Tipo de Item',
  'ConfirmDeleteTaskPlanSectionMessage': 'Confirma que desea borrar\n    <span style="color: red">{{ taskPlanSectionDesc }}</span> <br />\n    from Task Plan {{ taskPlanDesc }} ?',
  'ConfirmDeleteTaskPlanSubSectionMessage': 'Confirma que desea borrar <span style="color:red">{{subSection}}</span> <br> from Task Plan Section <b>{{section}}</b> ?',
  'EditTaskPlanSubSection': 'Editar Subsección de Plan de Tareas',
  'RequestforComments': 'Solicitud de Comentarios',
  'RequestforActionItems': 'Solicitud de Items de Acción',
  'PSSRInitializedSuccessfully': 'PSSR Initialized Successfully',
  'PrimeNgCalendarLabels': {
    'firstDayOfWeek': 0,
    'dayNames': [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado'
    ],
    'dayNamesShort': [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat'
    ],
    'dayNamesMin': [
      'Su',
      'Mo',
      'Tu',
      'We',
      'Th',
      'Fr',
      'Sa'
    ],
    'monthNames': [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ],
    'monthNamesShort': [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ],
    'today': 'Hoy',
    'clear': 'Despejar',
    'dateFormat': 'mm/dd/aa',
    'weekHeader': 'sem'
  },
  'Adminmenu': 'Menú admin',
  'Organizations': 'Organizaciones',
  'UserGuide': 'Guía del Usuario',
  'Administration': 'Administración',
  'ProjectSpecific': 'Específico del Proyecto',
  'Showdeletedprojects': 'Mostrar proyectos eliminados',
  'ProjectManagers': 'Gerentes de Proyecto',
  'ProjectTeamMembers': 'Miembros del Equipo del Proyecto',
  'Activate': 'Activar',
  'AddActionItem': 'Agregar Item de Acción',
  'Attention': 'Atención',
  'BacktoCategoryList': 'Volver a Lista de Categorías',
  'Category': 'Categoría',
  'CompleteTranslationWarning': 'Traducir Título de PSSR, Descripción, Título del Item de Acción y Comentarios del Ítem de Acción',
  'ConfirmTranslationRequest': 'Usted está por solicitar una traducción a {{language}} to {{email}}',
  'DeselectAll': 'Deseleccionar Todo',
  'FirstName': 'Nombre',
  'Itemsselected': 'Items Seleccionados',
  'Language': 'Idioma',
  'LastName': 'Apellido',
  'MOC#': 'MOC #',
  'New': 'Nuevo',
  'Org': 'Org',
  'ShowDeletedPSSRCCC': 'Mostrar PSSR / CCC Eliminado',
  'ShowDeletedPSSRCCCORR': 'Mostrar PSSR / CCC / ORR Eliminado',
  'ShowDeletedPSSRORR': 'Mostrar PSSR / ORR Eliminado',
  'Togglenavigation': 'Alternar navegación',
  'Youshouldnothaveemptysections': 'No debe haber secciones vacías',
  'togglesearch': 'alternar búsqueda',
  'GenericErrorTitle': 'Error Interno',
  'GenericErrorDescription': 'Se produjo un error al procesar su solicitud. Vuelva a intentar más tarde o contacte la mesa de ayuda.',
  'Kazakh': 'Kazakh',
  'PSSRmusthaveaFinalApprovertocompleteWalkdown': 'PSSR debe tener un Aprobador Final para completar la Recorrida',
  'ParticipatedinWalkthrough': 'Participató en la Recorrida',
  'Subsection': 'Sub sección',
  'AccessUntilApprove': 'Acceso hasta Aprobar',
  'AllNotifications': 'Todas las Notificaciones',
  'AreyousureyouwanttoSubCategory': 'Confirma que desea Sub Categoría',
  'DueDateMandatory': 'Fecha de Vencimiento Obligatoria',
  'FinalApproverSubmit': 'Enviar Aprobador Final',
  'FinalApproverInitiate': 'Iniciar Aprobador Final',
  'SectionsCollapsed': 'Secciones Colapsadas',
  'Portuguese': 'Portugués',
  'Dutch': 'Neerlandés',
  'SysAdmin': 'Admin del Sist',
  'ChevronDisclaimer': 'Este producto de software de Chevron es para uso sólo de usuarios autorizados. En la medida permitida por ley, al\n      usar este producto usted conoce y da consentimiento al monitoreo, acceso (incluido el\n      desencriptado e inspección del trafico encriptado de internet seleccionado),\n      uso o divulgación de Chevron de cualquier información generada, recibida o almacenada en este\n      sistema/dispositivo y renuncia a cualquier derecho o expectativa de privacidad en relación a su uso. Uno no autorizado\n      o impropio de este producto de software\n      en violación a las políticas corporativas de Chevron no serán toleradas y pueden resultar en acciones disciplinarias,\n      incluyendo terminación.',
  'ConfirmDeletePSSRTitle': 'Confirmar Eliminar',
  'ConfirmDeletePSSRMessage': 'PSSR se eliminará permanentemente, desea continuar?<br/>',
  'ConfirmNewTrainingTitle': 'Confirmar nueva Capacitación en PSSR',
  'ConfirmNewTrainingMessage': 'Se crearán nuevos PSSR de capacitación.<br/> Desea continuar?<br/>',
  'ConfirmDeleteActionItemMessage': 'Confirma que desea realizar esta acción?',
  'CannotCloseProjectDueOpenPSSRs': 'No se puede cerrar el proyecto porque hay PSSR abiertos',
  'PSSRIdisrequired': 'Se requiere ID de PSSR',
  'ImportFromisrequired': 'Importar desde es obligatorio.',
  'MOCIDisalreadypresentintheImportlist': 'MOC ID ya está presente en la lista de importación.',
  'ErrorhappenedwhileimportingPSSR': 'Ocurrió un error al importar PSSR',
  'RestoreProject': 'Restaurar Proyecto',
  'Spanish': 'Español',
  'ImportPSSRs': 'Importar PSSRs',
  'Import': 'Importar',
  'Application': 'Aplicación',
  'Records': 'Registros',
  'Imported': 'Importado',
  'Errors': 'Errores',
  'ImportAll': 'Importar Todos',
  'QAQCImport': 'QAQC Import',
  'Start': 'Comenzar PSSR',
  'ePSSRServiceNowTicketingInstructions': 'ePSSR Service Now Instrucciones de emisión de boletos',
  'ReportanIssue': 'Reportar un problema',
  'EnhancementRequest': 'Solicitud de mejora',
  'UserAccessRequest': 'Solicitud de acceso de usuario',
  'ApplicationSupportRequestForm': 'Dirijase a <a href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981" target="_blank">Solicitud de Soporte</a> en el portal de servicios de IT de Chevron',
  'UndertheApplicationFieldselectMobilePSSR': 'En el campo de la aplicación, seleccione <b>Mobile PSSR</b>.',
  'ToreportanissueorbugpleaseselectReportanIssueTosubmitanenhancementrequestoruseraccessrequestpleaseselectServiceRequestHitNext': 'Para informar un problema o error, seleccione <b>Informar un problema.</b> Para enviar una solicitud de mejora o un usuario solicitud de acceso, seleccione <b>Solicitud de servicio</b>. Presiona Siguiente.',
  'FillouttheDescriptionoftheProblemImpactandUrgency': 'Llene la Descripción del Problema, Impacto y Urgencia',
  'PressNextandthenSubmit': 'Presione Siguiente y luego Enviar.',
  'TheePSSRSupportTeamwillacknowledgethereceiptoftheticketin24hoursduringbusinesshours': 'El equipo de soporte de ePSSR acusará recibo del ticket en 24 horas durante el horario comercial.',
  'PleaseselectEnhancementsunderTypeofRequests': 'Seleccione <b>Mejoras</b> en Tipo de solicitudes.',
  'SelectPriorityLowModerateHigh': 'Seleccionar Prioridad <b>(Baja, Moderada, Alta)</b>',
  'FillouttheDescriptionofRequest': 'Complete la descripción de la solicitud',
  'SelectNextandSubmit': 'Seleccione Siguiente y Enviar.',
  'TheePSSRSupportTeamwillreviewenhancementrequestsaspartoftheSurfaceDigitalPIPlanningProcess': 'El equipo de soporte de ePSSR revisará las solicitudes de mejora como parte del proceso de planificación de Surface Digital PI',
  'PleaseselectRequestAccessunderTypeofRequests': 'Seleccione Solicitar acceso en Tipo de solicitudes.',
  'SelectAccessRequestTypeAddorRemove': 'Seleccione el tipo de solicitud de acceso <b>(Agregar o Eliminar)</b>',
  'SpecifyRoleandAccesstheUserneeds': 'Especifique el rol y el acceso que necesita el usuario...',
  'UserAlreadyPartipatedInPssr': 'Participant ["{{newTranslator}}"] has already participated in pssr',
  'TranslateActionItem': 'Traducir Item de Acción',
  'AbandonedPSSRInterval': 'Intervalo para PSSRs abandonados',
  'ShowOnlyDeletedPSSRs': 'Mostrar solo PSSRs borrados',
  'ShowOnlyAbandonedPSSRs': 'Mostrar solo PSSRs abandonados',
  'CloseConfirmation': 'Remember, by closing the PSSR, you confirm that all action items have been closed. Close PSSR?',
  'ReimportAllSharedUsers': 'Re-import All Shared Users',
  'ImportSharedUsersFailed': 'Import failed',
  'ImportSharedUsersIncompleted': 'Import incomplete',
  'ImportSharedUsersCompleted': 'Import complete in: {{ minutes }}mins',
  'ConfirmReimportAllSharedUsersHeader': 'Re-import All Shared Users',
  'ConfirmReimportAllSharedUsersMessage': 'You are about to update all shared users configuration.<br> This operation could take several minutes to complete.',
  'SendaremindertoAssignees': 'Send a reminder to Assignees',
  'SendReminderInstructions': 'Send reminder to assignees for all opened AIs.\nWhen PSSR is in Pending Approval status, \nApprovers will receive notification.',
  'Sendamessage': 'Send a message',
  'SendanemailmessagetoallPSSRParticipants': 'Send an email message to all PSSR Participants',
  'ImportSharedUserOptionWhitePagesTitle': 'White Pages Integration',
  'ImportSharedUserOptionWhitePagesDescription': 'Use this type of shared email if you need to sync with White Pages. The list of users will be updated automatically over time. Some users may be automatically added or removed from this shared email.',
  'ImportSharedUserOptionCustomTitle': 'Custom Shared Email',
  'ImportSharedUserOptionCustomDescription': 'Manually manage the list of users.',
  'ImportActionItemsSuccessfully': 'Imported Action Items successfully',
  'ImportActionItemsNone': 'No Action Items to import',
  'FR.Actions.CompleteReview': 'Completar Revisión',
  'FR.Titles.FunctionalReview': 'Revisión Funcional',
  'FR.Warnings.NotFoundFunctionalReview': 'No existe una revisión funcional activa con los filtros seleccionados. Presione el botón de "Crear" para comenzar una revisión funcional nueva.',
  'FR.Warnings.CannotStartFunctionalReview': 'Por favor seleccione un Tipo de revisión y Asset para comenzar una revisión funcional',
  'FR.Titles.ChecklistSections': 'Secciones de Plan de Tareas',
  'FR.Titles.CreateNewSection': 'Crear Nueva Sección',
  'FR.Titles.CreateNewSubSection': 'Crear Nueva Subsección',
  'FR.Titles.ManageChecklist': 'Administrar Contenido de {{ Description }}',
  'FR.Forms.Labels.ReviewType': 'Tipo de Revisión',
  'FR.Forms.Labels.Description': 'Descripción',
  'FR.Tables.Headers.ID': 'ID',
  'FR.Tables.Headers.Priority': 'Prioridad',
  'FR.Tables.Headers.Action': 'Acción',
  'FR.Tables.Headers.Comment': 'Comentario',
  'FR.Tables.Headers.UpdatedBy': 'Actualizado por',
  'FR.Tables.Headers.Answer': 'Respuesta',
  'FR.Tables.Headers.Question': 'Pregunta',
  'FR.Tables.Headers.Code': 'Código',
  'FR.Tables.Headers.ReviewType': 'Tipo de Revisión',
  'FR.Tables.Headers.Description': 'Descripción',
  'FR.Actions.CreateNewItem': 'Crear Nuevo Item',
  'FR.Actions.BackToChecklists': 'Regresar a Lista de Pendientes',
  'FR.Titles.CreateChecklistItem': 'Crear Item para Lista de Pendientes',
  'FR.Actions.BackToChecklistsManage': 'Regresar a Administrar Lista de Pendientes',
  'FR.Titles.EditChecklistItem': 'Editar Item para Lista de Pendientes',
  'FR.Titles.ConfigureActionItem': 'Configurar AI',
  'FR.Titles.ConfigureComment': 'Configurar Comentario',
  'FR.Forms.Labels.Action': 'Acción',
  'FR.Forms.Labels.SubSection': 'Sub Sección',
  'FR.Forms.Labels.Comment': 'Comentario',
  'FR.Forms.Labels.Priority': 'Prioridad',
  'FR.Forms.Errors.InputRequired': 'Este campo es obligatorio',
  'FR.Forms.Errors.RepeatedReviewType': 'Este tipo de revisión ya ha sido usada',
  'FR.Forms.Errors.ReviewTypeMaxLength': 'El tipo de revisión debe tener solo {{maxlength?.requiredLength}} caracteres',
  'FR.Titles.CreateChecklist': 'Crear Lista de Pendientes',
  'FR.SubTitles.ReviewTypeWarning': '[Nota]:&nbsp;El tipo de revisión necesita ser unica, y no puede se renombrada luego de que la Lista de Pendientes se cree.',
  'FR.Errors.UnavailableMOC': 'Por favor, use MOC para acceder a la Revisión Funcional.',
  'FR.Errors.BuAssetNotSelected': 'Por favor elija una Unidad de Negocio y Asset',
  'FR.Errors.ReviewError': 'Ha ocurrido un error mientras se completaba la revisión',
  'FR.Forms.Labels.SectionDescription': 'Descripción de Sección',
  'FR.Forms.Labels.SubsectionDescription': 'Descripción de Subsección',
  'FR.Messages.CommentAdded': '¡Comentario agregado!',
  'FR.Messages.CommentUpdated': '¡Comentario actualizado!',
  'FR.Messages.CommentDeleted': '¡Comentario borrado!',
  'FR.Messages.ActionItemAdded': '¡Item de acción agregado!',
  'FR.Messages.ActionItemUpdated': '¡Item de acción actualizado!',
  'FR.Messages.ActionItemDeleted': '¡Item de acción borrado!',
  'FR.Messages.ResponseAdded': '¡Pregunta agregada!',
  'FR.Messages.ResponseAnswered': '¡Pregunta respondida!',
  'FR.Messages.ResponseRemoved': '¡Pregunta borrada!',
  'FR.Messages.ChecklistCreated': '¡Lista de pendientes agregada!',
  'FR.Messages.ChecklistUpdated': '¡Lista de pendientes actualizada!',
  'FR.Messages.ChecklistSectionUpdated': '¡Se ha actualizado la sección en la lista de pendientes!',
  'FR.Messages.ChecklistSubSectionUpdated': '¡Se ha actualizado la sub sección en la lista de pendientes!',
  'FR.Messages.ChecklistItemCreated': '¡Se ha agregado un item en la lista de pendientes!',
  'FR.Messages.ChecklistItemUpdated': '¡Se ha actualizado un item en la lista de pendientes!',
  'FR.Actions.Add': 'Agregar',
  'FR.Actions.Create': 'Crear',
  'FR.Actions.Save': 'Guardar',
  'FR.Actions.Cancel': 'Cancelar',
  'FR.Messages.ReviewCompleted': '¡Revisión completada satisfactoriamente!',
  'FR.Dialogs.Actions.Yes': 'Sí',
  'FR.Dialogs.Actions.No': 'No',
  'FR.Dialogs.Title.Delete': 'Confirmar Borrar',
  'FR.Dialogs.Description.Delete': 'Va a eliminar este item, desea continuar?',
  'FR.Titles.EditChecklistSection': 'Editar sección de Lista de Pendientes',
  'FR.Titles.EditChecklistSubSection': 'Editar subsección de Lista de Pendientes',
  'FR.Forms.Labels.Code': 'Código',
  'FR.Forms.Labels.CreatedBy': 'Creado por',
  'FR.Forms.Labels.UpdatedDate': 'Fecha de actualización',
  'FR.Forms.Labels.UpdatedBy': 'Actualizado por',
  'FR.Forms.Labels.ShouldAddCommentToQuestion': 'Requiere Comentario para Pregunta NA',
  'FR.Titles.LastModification': 'Última Modificación',
  'FR.Titles.Checklist': 'Listas de Pendientes',
  'FR.Filters.BusinessUnit': 'Unidad de Negocio',
  'FR.Filters.TaskPlan': 'Plan de Tareas',
  'FR.Actions.CreateNewChecklist': 'Crear Nuevo',
  'FR.Actions.Edit': 'Editar',
  'FR.Actions.Manage': 'Administrar',
  'FR.Actions.Delete': 'Borrar',
  'FR.Titles.MOC': 'MOC',
  'FR.Titles.EditChecklist': 'Editar Lista de Pendientes',
  'Alerts.OutdatedApp.Message': 'Tenga en cuenta que la aplicación móvil actual dejará de funcionar pronto. Recomendamos a todos los usuarios que actualicen la aplicación móvil \'PSSR 3\' lo antes posible.'
};
