import { DictionaryEntries } from './dictionary-entries';

export const PT: DictionaryEntries = {
    '&': '&',
    'AllBactionitemsmustbeclosed.': 'All B action items must be closed',
    '3rdPartyexternal': 'Contratado',
    'ABChecklist': 'Lista de verificação AB',
    'ABChecklistfor': 'Lista de verificação AB para',
    'ACTIONS': 'AÇÕES',
    'AChecklist': 'Lista de verificação A',
    'Action': 'Ação',
    'ActionConfirmation': 'Tem certeza de que deseja realizar esta ação?',
    'ActionItemAssignee': 'Responsável pelo Item de Ação',
    'ActionItemisneededtocompletequestion': 'O Item de Ação é necessário para concluir a questão',
    'ActionItems': 'Itens de Ação',
    'ActionIsRequired': 'Campo de Ação é obrigatório',
    'ActionWillBeTransferred': 'A Ação será transferida para as operações',
    'Actions': 'AÇÕES',
    'Actionwillbetransferredtooperations': 'A Ação será transferida para as operações',
    'Active': 'Ativo',
    'Add': 'Adicionar',
    'AddABChecklisttoProject': 'Adicionar lista de verificação AB ao Projeto',
    'AddABChecktoEndDevice': 'Adicionar ABCheck ao Dispositivo Final',
    'AddComment': 'Adicionar Comentário',
    'AddFeedback': 'Adicionar Feedback',
    'AddGroup': 'Adicionar Grupo',
    'AddNew': 'ADICIONAR NOVO',
    'AddQuestion': 'Adicionar pergunta',
    'AddRedLining': 'Adicionar marcações em vermelho',
    'AddSignature': 'ADICIONAR ASSINATURA',
    'AddTemplate': 'Adicionar Modelo',
    'AddUser': 'Adicionar usuário',
    'AddVersion': 'Adicionar Versão',
    'AddYellowLining': 'Adicionar marcações em amarelo',
    'Addactionitem': 'Adicionar Item de Ação',
    'AddtoEAM': 'Adicionar ao EAM',
    'Alert': 'Alerta',
    'Alltherelatedsectionssubsectionsitemswillberemoved': 'Todas as seções | subseções | itens relacionados serão removidos',
    'Alltherelatedsubsectionsitemswillberemoved': 'Todas as subseções | itens relacionados serão removidos.',
    'SendAllNotificationsToApproversInstruction': '*If Checked Final Approver will receive all notifications',
    'AllP3actionmustbeclosedtosubmitforapproval': 'Todas as ações {{P3Label}} devem ser fechadas para enviar para aprovação',
    'AllowAIOwnerToAttach': 'Permitir que o Proprietário da IA anexe',
    'AllowAIOwnerToAttachInstruction': 'Se marcado, permite que o proprietário do item de ação anexe arquivos em qualquer status da IA enquanto o PSSR não estiver fechado',
    'AllowApproverToSubmitForApproval': 'Permitir que o aprovador final envie para aprovação',
    'AllowLeadsDeleteQuestions': 'Permitir que Líderes excluam perguntas',
    'AllowMembersCompletePSSR': 'Permitir que membros concluam a PSSR',
    'AllowMembersToCompletePSSR': 'Permitir que os membros concluam a PSSR',
    'AllowMultiApprover': 'Permitir vários aprovadores',
    'AllowNAResponses': 'Permitir respostas NA',
    'AllowWetSignature': 'Permitir assinatura física da PSSR',
    'Allpages': 'Todas as páginas',
    'Alltherelated': 'Todos os relacionados',
    'Alltherelateditemswillberemoved': 'Todos os itens relacionados serão removidos',
    'AngularTemplatedocumentation': 'Documentação do modelo angular',
    'Answer': 'Resposta',
    'AnswerallquestionsandsignaturestoCompletethePSSR': 'Responder todas as perguntas & assinaturas para concluir a inspeção de campo',
    'AnswerallquestionssignaturestoCompletethePSSR': 'Responder todas as perguntas & assinaturas para concluir a inspeção de campo',
    'Answerwillbecleared': 'A resposta será apagada',
    'Approve': 'Aprovar',
    'Approvebywetsignature': 'Aprovar por assinatura física',
    'Approved': 'Aprovado',
    'ApprovedOn': 'Aprovado em',
    'AreyousureyouwanttodeleteABChecklistbelow': 'Tem certeza de que deseja excluir a Lista de Verificação AB abaixo',
    'AreyousureyouwanttodeleteSubCategory': 'Are you sure you want to delete Sub Category',
    'Area': 'Área',
    'Areyousureyouwanttodelete': 'Tem certeza de que deseja excluir',
    'AreyousureyouwanttodeleteTaskPlanbelow': 'Tem certeza de que deseja excluir o Plano de Tarefas abaixo',
    'AssignedToLeadFinalApprover': 'Atribuído ao líder/aprovador final',
    'Assignedto': 'Atribuído a',
    'Assignedtome': 'Atribuído a mim',
    'Assignee': 'Responsável',
    'Assignto': 'Atribuir a',
    'Attach': 'Anexar',
    'AttachedFiles': 'Arquivos anexados',
    'AutoLoadImages': 'Carregar imagens automaticamente',
    'Autoclosems': 'Fechamento automático (ms.)',
    'Available': 'Disponível',
    'BChecklist': 'Lista de verificação B',
    'BUAdmin': 'Admin da BU',
    'BUAdmins': 'Administradores de BUs',
    'Back': 'Voltar',
    'BacktoSubCategoryList': 'Voltar à lista de subcategorias',
    'BacktoTaskPlan': 'Voltar ao Plano de Tarefas',
    'BackToList': 'Voltar para a lista',
    'Backto': 'Voltar a',
    'BacktoABChecksList': 'Voltar à lista de verificação AB',
    'BacktoAreaList': 'Voltar à lista de áreas',
    'BacktoGroupList': 'Voltar à lista de grupos',
    'BacktoList': 'Voltar à Lista',
    'BacktoProjectDetail': 'Voltar aos Detalhes do Projeto',
    'BacktoProjectList': 'Voltar à Lista de Projetos',
    'BacktoTaskPlanManage': 'Voltar ao Gerenciamento do Plano de Tarefas',
    'Bug': 'Bug',
    'BuildNumber': 'Número de versão',
    'BusinessUnit': 'Unidade de Negócios',
    'BusinessUnitisrequired': 'É necessária uma Unidade de Negócios',
    'BusinessUnits': 'Unidade de Negócios',
    'By': 'Por ',
    'ByDefaultProject': 'Por padrão é \'Projeto\'',
    'CCCORR': 'PSSR / CCC / ORR',
    'CanOnlyPerformAttachments': 'Só pode executar anexos',
    'Cancel': 'Cancelar',
    'Canceled': 'Cancelado',
    'CaptureSignature': 'Capturar assinatura',
    'Categories': 'Categorias',
    'ChangeRequestApproved': 'Solicitação de alteração aprovada',
    'ChangeRequestDenied': 'Solicitação de alteração negada',
    'Chevron': 'Chevron',
    'ChevronCorporationAllrightsreserved': 'Corporação Chevron. Todos os direitos reservados.',
    'ChevronDirectory': 'Diretório Chevron',
    'ChevronDirectory/SharedUsers': 'Diretório Chevron/Usuários Compartilhados',
    'ChevronIE': 'Chevron I&E',
    'ChevronSFT': 'Chevron SFT',
    'Clear': 'Limpar',
    'ClearFilter': 'Limpar filtro',
    'ClearQuestion': 'Limpar Pergunta',
    'ClearSelection': 'Limpar Seleção',
    'Close': 'Fechar',
    'CloseProjWarning': 'Você quer fechar este projeto?',
    'CloseProject': 'Fechar Projeto',
    'Closed': 'Fechado',
    'ClosedBy': 'Fechado Por',
    'ClosedOn': 'Fechado em',
    'Code': 'Código',
    'ComissionDate': 'Data da Comissão',
    'Comment': 'Comentário',
    'Commentgoeshere': 'Comentário entra aqui',
    'Commentisneededtocompletequestion': 'Um Comentário é necessário para concluir a questão',
    'CompleteTranslation': 'Tradução Completa',
    'Completed': 'Concluído',
    'CompletedQuestions': 'Perguntas concluídas',
    'ConfigureActionItem': 'Configurar Item de Ação',
    'Confirm': 'Confirmar',
    'ConfirmDeleteHeader': 'Confirmar rejeição',
    'ConfirmDeleteMessage': 'Se você rejeitar o item de ação, não poderá aprovar esta PSSR. Você precisará alterar o status para inspeção de campo completa na parte inferior da página.',
    'ConfirmDeletePSSRFileHeader': 'Confirmar exclusão',
    'ConfirmDeletePSSRFileMessage': 'Arquivo <b>{{fileName}}</b> será removido permanentemente, continuar?<br/>',
    'ConfirmPSSRApprovalHeader': '',
    'ConfirmPSSRApprovalMessage': 'Tem certeza de que deseja aprová-la?',
    'ConfirmPSSRReOpenMessage': 'Are you sure you want to re-open this PSSR?',
    'ConfirmPSSRReOpenHeader': '',
    'ConfirmRejectionHeader': 'Confirmar rejeição',
    'ConfirmRejectionMessage': 'Se você rejeitar o item de ação, você não poderá aprovar esta PSSR. Você precisará alterar o status para inspeção de campo concluída pressionando o botão <b>{{actionsButton}}</b> na parte inferior da página.',
    'ConfirmTranslationComplete': 'Tem certeza de que Título, Descrição, Itens de Ação e Comentários foram traduzidos?',
    'Confirmdelete': 'Confirmar exclusão',
    'Confirmtoproceed': 'Por favor, <b>confirme</b> para continuar',
    'ContinueConfirmation': 'Deseja continuar',
    'Contractor': 'Contratada',
    'CopiedImage': 'Imagem <b>{{docName}}</b> foi copiada',
    'Copy': 'Copiar',
    'CopyEquipmentstoProject': 'Copiar Equipamentos para Projeto',
    'CopyImage': 'Copiar imagem',
    'CopyImageError': 'Erro ao copiar imagem',
    'CopyfromProject': 'Copiar do Projeto',
    'CopyingImage': 'Copiando imagem...',
    'Copyto': 'Copiar para',
    'CostCode': 'Código de Custo',
    'Create': 'Criar',
    'CreateNewItem': 'Criar novo item',
    'CreateBusinessUnit': 'Criar Unidade de Negócios',
    'CreateGroup': 'Criar grupo',
    'CreateNew': 'Criar novo',
    'CreateNewSubCategory': 'Criar nova subcategoria',
    'CreatePSSR': 'Criar {{PssrTitle}}',
    'CreatePSSRCCC': 'Criar PSSR / CCC',
    'CreatePSSRCCCORR': 'Criar PSSR / CCC / ORR',
    'CreatePSSRORR': 'Criar PSSR / ORR',
    'CreateProject': 'Criar Projeto',
    'CreateSubCategory': 'Criar subcategoria',
    'CreateTraining': 'Criar treinamento',
    'CreateUser': 'Criar usuário',
    'CreatedBy': 'Criado por',
    'CreatedOn': 'Criado em',
    'DBVersion': 'Versão DB',
    'Date': 'Data',
    'DateCaptured': 'Data de captura',
    'Dates': 'Datas',
    'Default': 'Padrão',
    'DefaultSummarySignatureDisclaimer': 'Isenção de responsabilidade de assinatura resumida padrão',
    'DefaultWalkdownSignatureDisclaimer': 'Isenção de responsabilidade de assinatura de inspeção de campo padrão',
    'Delete': 'Excluir',
    'DeleteActionItem': 'Excluir item de ação',
    'DeleteDevice': 'Concorda em Excluir dispositivo selecionado',
    'DeleteEndDevice': 'Excluir dispositivo final',
    'DeleteEquipment': 'Excluir equipamento',
    'DeleteEquipmentTemplateGroup': 'Excluir grupo de modelos de equipamentos',
    'DeleteManufacturerModel': 'Excluir fabricante - modelo',
    'DeleteProject': 'Esta ação resultará na exclusão deste projeto e de todos os PSSR envolvidos. Deseja prosseguir?',
    'DeleteTaskPlanSection': 'Excluir seção do plano de tarefas',
    'DeleteTaskPlanSubSection': 'Excluir subseção do plano de tarefas',
    'DeleteselectedEquipmentTemplateGroup': 'Concorda em Excluir Grupo de Modelo de Equipamento selecionado',
    'Description': 'Descrição',
    'DescriptionScope': 'Descrição e Escopo',
    'Descriptioninputwasalreadyused': 'A entrada de descrição já foi usada',
    'Descriptionisrequired': 'A descrição é obrigatória',
    'DeviceType': 'Tipo de dispositivo',
    'DeviceTypeEAM': 'Tipo de dispositivo - Nome do EAM - Descrição do EAM',
    'Disabled': 'Desabilitado',
    'Discard': 'Descartar',
    'DisplayRichEditor': 'Exibir Rich Editor',
    'Documents': 'Documentos',
    'Download': 'Download',
    'DownloadError': 'Erro no download do arquivo',
    'DownloadingDocument': 'Baixando documento...',
    'DoyouagreetoDeleteselectedManufacturerModel': 'Concorda em Excluir Fabricante - Modelo selecionado',
    'DoyouagreetoDeleteselectedequipment': 'Concorda em Excluir equipamento selecionado',
    'DoyouagreetoRemoveselectedparticipant': 'Concorda em remover o participante selecionado',
    'Draft': 'Rascunho',
    'DraftCreatedOn': 'Rascunho criado em',
    'Dropdown': 'Menu suspenso',
    'DueDate': 'Data de Vencimento',
    'RequireActionItemDueDateInstruction': 'If checked, the Due Date for action items will be mandatory',
    'EAMClass': 'Classe de EAM',
    'EAMCriticality': 'Criticidade de EAM',
    'EAMDepartment': 'Departamento de EAM',
    'EAMDescription': 'Descrição de EAM',
    'EAMName': 'Nome de EAM',
    'EAMType': 'Tipo de EAM',
    'ENDDEVICESPECIFICABCHECKLISTS': 'LISTAS DE VERIFICAÇÃO AB ESPECÍFICAS DO DISPOSITIVO FINAL',
    'EQUIPMENT': 'EQUIPAMENTO',
    'Edit': 'Editar',
    'EditBusinessUnit': 'Editar Unidade de Negócios',
    'EditGroup': 'Editar Grupo',
    'EditImageError': 'Erro ao editar imagem',
    'EditOrganization': 'Edit {{OrganizationLabel}}',
    'EditParticipant': 'Editar participante',
    'EditProject': 'Editar projeto',
    'EditRedLining': 'Editar marcações em vermelho',
    'EditSubCategory': 'Editar subcategoria',
    'EditTaskPlanSection': 'Editar seção do plano de tarefas',
    'EditYellowLining': 'Editar marcações em amarelo',
    'Email': 'E-mail',
    'EndDevice': 'Dispositivo final',
    'EndDeviceList': 'Lista de dispositivos finais',
    'EndDeviceTemplate': 'Modelo de dispositivo final',
    'EndDevices': 'Dispositivos finais',
    'English': 'Inglês',
    'EnglishTranslationInProgress': 'Tradução para o inglês em andamento',
    'Equipment': 'Equipamento',
    'EquipmentGroup': 'Grupo de Equipamentos',
    'EquipmentList': 'Lista de equipamento',
    'EquipmentTemplate': 'Modelo de Equipamento',
    'EquipmentTemplateGroup': 'Grupo de Modelo de Equipamento',
    'EquipmentTemplateGroups': 'Grupos de modelos de equipamentos',
    'EquipmentTemplates': 'Modelos de Equipamento',
    'EraseLocalData': 'Apagar dados locais',
    'ErrorImageEditMessage': 'Não foi possível abrir a imagem para editar',
    'ErrorImageMessage': 'Não foi possível copiar a imagem',
    'ErrorImageUndoMessage': 'Não foi possível desfazer a imagem',
    'EventLabel': 'Etiqueta de Nome do Evento',
    'EventName': 'Nome do evento',
    'ExistingNonChevronShared': 'Existente não Chevron/Compartilhado',
    'ExportABChecklistsasPDF': 'Exportar listas de verificação AB como PDF',
    'ExportExcel': 'Exportar Excel',
    'Facility': 'Instalação',
    'FacilityHierarchy': 'Hierarquia de instalações',
    'FacilityNo': 'N.º da instalação',
    'FacilityOperationTeam': 'Equipe de Operação da Instalação',
    'Factory': 'Fábrica',
    'Featurerequest': 'Solicitação de recurso',
    'Feedback': 'Feedback',
    'FeedbackScreen': 'Tela de Feedback',
    'Fieldisrequired': 'Este campo é obrigatório',
    'FileDuplicate': 'O documento que você está tentando enviar já existe. Clique no botão Cancelar para cancelar o upload ou clique no botão Upload para substituir o documento existente',
    'Files': 'Arquivos',
    'FilesUploaded': 'Os arquivos foram carregados com sucesso',
    'FilesUploadedWithWarning': 'Alguns arquivos foram carregados com sucesso. Por favor, tente novamente.',
    'FillAndSave': 'Preencha os campos marcados como obrigatórios. Clique em Salvar.',
    'Filter': 'Filtrar',
    'FilterKMSListbyStatus': 'Filtrar Lista KMS por Status',
    'FilterNumberofKMSItemsreturned': 'Filtrar número de itens KMS devolvidos',
    'FilteredActionItemsInitialWarning': 'A página é filtrada por padrão para o usuário atual e status aberto',
    'FinalApproval': 'Aprovação final',
    'FinalApprover': 'Aprovador final',
    'RequireFinalApproverBeforeInitiationInstruction': 'If checked, the PSSR will require to have a Final Approver to Initiate it',
    'FinalApproverPresent': 'If approver is marked present, final sign off can be provided on device, if not submit for approval and Sync',
    'FinalApproverSignature': 'Assinatura do aprovador final',
    'FoundImages': 'Imagens encontradas',
    'FullName': 'Nome completo',
    'GENERALINFORMATION': 'INFORMAÇÕES GERAIS',
    'GeneralInformation': 'INFORMAÇÕES GERAIS',
    'GeneratePSSR': 'Gerar PSSRs',
    'GetEAMLoadsheet': 'Obter planilha de EAM',
    'GetEquipmentList': 'Obter lista de equipamentos',
    'GoBack': 'Voltar',
    'Group': 'Grupo',
    'GroupName': 'Nome do grupo',
    'GroupNames': 'Nomes de grupos',
    'UserGroups': 'Grupos de usuários',
    'Hi': 'Olá',
    'HideCode': 'Ocultar código',
    'HierarchyLabel1': 'Etiqueta de Hierarquia 1',
    'HierarchyLabel2': 'Etiqueta de Hierarquia 2',
    'HierarchyLabel3': 'Etiqueta de Hierarquia 3',
    'HierarchyLabel3show': 'Rótulo de hierarquia 3 (exibir)',
    'I': 'Eu',
    'ID': 'ID',
    'Iagree': 'Concordo',
    'IagreethatallPSSRchecklistitems': 'Concordo que todos os itens da lista de verificação de PSSR e todos os itens de ação necessários para uma inicialização segura foram registrados com precisão',
    'Idontagree': 'Não concordo',
    'Image': 'Imagem',
    'ImageCaption': 'Legenda',
    'ImageCapture': 'Capturar imagem',
    'Images': 'Imagens',
    'ImplementationInProgress': 'Implementação em andamento',
    'ImplementationInProgressEdit': 'Editar implementação em andamento',
    'ImportSharedUser': 'Importar usuário compartilhado',
    'ImportMOCActions': 'Import MOC Action Items',
    'InProgress': 'Em andamento',
    'Inactive': 'Inativo',
    'IncludeProjectTeam': 'Incluir membros da equipe de projeto',
    'Info': 'Informações',
    'Initiate': 'Iniciado',
    'Initiated': 'iniciado',
    'InitiatedOn': 'Iniciado',
    'Input': 'Entrada',
    'InputSharedUserEmail': 'Inserir e-mail de usuário compartilhado',
    'InputdescriptionfornewSection': 'Descrição de entrada para nova seção',
    'Instructions': 'Instruções',
    'InternalError': 'Erro interno',
    'IsAdmin': 'É Admin',
    'IsExternalUser': 'Usuário externo?',
    'IsSectionOrderEnabled': 'Ativar ordem personalizada para seções',
    'ItemCode': 'Código do item',
    'ItemCodeMsg': '- Código do item\t  <b>',
    'ItemDescriptionMsg': '</b><br/>   - Descrição do item\t <b>',
    'Itemwillbetransferredtooperations': 'O item será transferido para as operações',
    'Iteration': 'Iteração',
    'KMSInstruction': 'Pesquise por um número MOC específico (esquerda) ou selecione na lista suspensa MOC List (direita)',
    'LOCATION': 'LOCALIZAÇÃO',
    'LimitAccessToAIOwner': 'Limitar o acesso ao proprietário da IA',
    'LimitAccessToAIOwnerInstruction': 'Se marcado:  não permite que o Proprietário/Responsável pelo Item de Ação modifique a descrição da IA, data de vencimento ou exclua a IA. ',
    'AllowTeamLeadToAttach': 'Allow team lead to attach and modify AI',
    'AllowTeamLeadToAttachInstruction': 'Se marcado: para Líderes, após aprovação pendente só podem anexar arquivos, responsáveis, status e comentário',
    'Links': 'Links',
    'List': 'Lista',
    'Load': 'Carregar',
    'LoadImages': 'Carregar imagens',
    'LoadNewQAQC': 'Carregar novos itens de QA/QC',
    'Loading': 'Carregando',
    'LocaldbID': 'ID de DB local',
    'Location': 'Localização',
    'LogScreen': 'Tela de Registro',
    'Login': 'Entrar',
    'LoginExpiresOn': 'Login Expira Em',
    'LoginProfile': 'Perfil do login',
    'Logout': 'Sair',
    'LookupbyPasscode': 'Pesquisa por senha',
    'Loose': 'Soltar',
    'MOC': 'MOC',
    'MOCWO': 'N.º de MOC ou WO',
    'MOCWOProjectNumberRestrictionEnabled': 'Restrição de número de projeto MOCWO habilitada',
    'Manage': 'Gerenciar',
    'ManageContentof': 'Gerenciar conteúdo de',
    'ManagesEquipments': 'Gerencia Equipamentos',
    'Mandatory': 'Obrigatório',
    'Manufacturer': 'Fabricante - Modelo',
    'ManufacturerModel': 'Fabricante - Modelo',
    'ManufacturerModelfor': 'Fabricante - Modelo para',
    'MarkasClosed': 'Marcar como Fechado',
    'Message': 'Mensagem',
    'Milestone': 'Marco QAQC',
    'MilestoneAndWorkOrders': 'Marcos/Ordens de Serviço QAQC',
    'MilestoneWorkOrder': 'WO de Marco QAQC',
    'MilestoneWorkOrders': 'Ordens de serviço de Marco QAQC',
    'Milestones': 'Marcos QAQC',
    'MilestonesWorkOrdersNoavailable': 'Não há marco de WO disponível para QAQC',
    'MobileAppVersions': 'Versões de aplicativos móveis',
    'MobileFeature': 'Recurso disponível apenas em disp. móvel',
    'MobilePSSR': 'PSSR móvel',
    'Model': 'Modelo',
    'Multiple': 'Múltiplo',
    'MultipleFinalApprovers': 'Se marcado, permite que a PSSR tenha vários Aprovadores Finais',
    'Mustbecompletedbeforetostartup': 'O item deve ser concluído antes da inicialização',
    'NA': 'N/A',
    'Name': 'Nome',
    'NewNonChevron': 'Novo não-Chevron',
    'NewOrganization': 'Nova Organização',
    'NewPSSR': 'Nova PSSR',
    'NewPSSRCCC': 'Novo PSSR / CCC',
    'NewPSSRCCCORR': 'Novo PSSR/CCC/ORR',
    'NewPSSRORR': 'Novo PSSR / ORR',
    'NewSectionDescription': 'Nova Descrição da Seção',
    'No': 'Não',
    'Nomobileappversionsyet': 'Nenhuma versão de aplicativo móvel ainda',
    'NoActionItems': 'Nenhum iten de Ação',
    'Nofeedbackyet': 'Não há feedback ainda',
    'Nomilestones': 'Não há marcos/ordens de serviço disponíveis para QAQC',
    'NonMOC': 'Sem MOC',
    'NotRegistered': 'Não registrado',
    'NotVisibleMobile': 'Esta seção ainda não está visível no celular até que o usuário sincronize',
    'Notauthorized': 'Não Autorizado',
    'Note': 'Observação',
    'Notfound': 'Não encontrado',
    'Notifications': 'Notificações',
    'OPEN': 'ABRIR',
    'OPSignature': 'Assinatura OP',
    'ORR': 'ORR',
    'OnHold': 'Em espera',
    'OnlyFinalApprovercanApprovePSSR': 'Somente o aprovador final pode aprovar a PSSR',
    'OnlyPSSRTeamLeadClosePSSR': 'Somente o líder da equipe de PSSR pode encerrar a PSSR',
    'OnlyPSSRTeamLeadcanClosePSSR': 'Somente o líder da equipe de PSSR e o aprovador final podem fechar a PSSR',
    'OnlyPSSRTeamLeadcanInitiatePSSR': 'Somente o líder da equipe de PSSR pode iniciar a PSSR',
    'OnlyPSSRTeamLeadcanSubmitforPendingApproval': 'Somente o Líder da equipe de {{PssrTitle}} pode enviar para aprovação pendente.',
    'OnlyTeamLeadorSectionLeadcananswerthequestions': 'Somente o líder de equipe ou líder de seção pode responder às perguntas.',
    'Open': 'ABRIR',
    'OpenStatus': 'ABRIR',
    'OpenError': 'Erro ao abrir arquivo',
    'OpenErrorMsg': 'Não foi possível abrir o documento',
    'OpeningDialog': 'Não foi possível copiar a imagem',
    'OpeningImage': 'Abrindo imagem...',
    'OperationTeam': 'Equipe de Operação',
    'OperationsRepresentative': 'Representante de Operações',
    'Optional': 'Opcional',
    'OrgLabel': 'Rótulo da organização',
    'Organization': 'Organização',
    'OrganizationAdmin': 'Administrador da organização',
    'OrganizationLabel': 'Etiqueta de Organização',
    'Organizationisrequired': 'A organização é necessária',
    'Other': 'Outros',
    'OtherOptions': 'Outras opções',
    'OutstandingQuestions': 'Perguntas Pendentes',
    'P3ActionItemsMustBeClosed': '{{P3Label}} Os itens de ação devem ser fechados.',
    'P3Label': 'Etiqueta P3',
    'P4ActionItemsMustBeClosed': '{{P4Label}} Os itens de ação devem ser fechados.',
    'P4Label': 'Etiqueta P4',
    'P5Label': 'Etiqueta P5',
    'PARTICIPANTS': 'PARTICIPANTES',
    'PDFPreview': 'Pré-visualização de PDF',
    'PIDVerificationRedLining': 'Verificação P&ID (marcações em vermelho)',
    'PIDVerificationYellowLining': 'Verificação P&ID (marcações em amarelo)',
    'PROJECTSPECIFICABCHECKLISTS': 'LISTAS DE VERIFICAÇÃO AB ESPECÍFICAS DO PROJETO',
    'PROJECTSTATUS': 'STATUS DO PROJETO',
    'PSSR': 'PSSR',
    'PSSRID': 'PSSR ID',
    'PSSRCCC': 'PSSR/CCC',
    'PSSRInitiatorGroup': 'Grupo Iniciador de PSSR',
    'PSSRLabel': 'Rótulo de PSSR',
    'PSSRMembers': 'Membros de {{PssrTitle}}',
    'PSSRORR': 'PSSR/ORR',
    'PSSRSections': 'Seções de PSSR',
    'PSSRStatus': 'Situação do {{PssrTitle}}',
    'PSSRTeam': 'Equipe de {{PssrTitle}}',
    'PSSRTeamLead': 'Líder de equipe de {{PssrTitle}}',
    'PSSRTemplates': 'Modelo de PSSR',
    'PSSRTemplateisrequired': 'O modelo de PSSR é obrigatório',
    'PSSRTitle': 'Título do PSSR',
    'PSSRType': 'Tipo de PSSR',
    'PSSRTypes': 'Tipos de PSSR',
    'PSSRmusthaveaFinalApprovertocompleteWalkthrough': 'A PSSR deve ter um aprovador final para concluir a inspeção de campo',
    'PSSRs': 'PSSRs',
    'NavigateToMainPage': '{{PssrTitle}}s',
    'Page': 'Página',
    'Search': 'Procurar',
    'Participants': 'PARTICIPANTES',
    'ParticipantsinBlueparticipatedinWalkdown': 'Participantes em Azul participaram da inspeção de campo',
    'ParticipatedinWalkdown': 'Participou da Inspeção de campo',
    'PendingApproval': 'Aprovação pendente',
    'PendingApprovalSubmittedOn': 'Aprovação pendente enviada em',
    'Photos': 'Fotos',
    'PickError': 'Erro ao escolher arquivo',
    'PleaseNotify': 'Por favor, notifique os participantes para sincronizarem seus dispositivos móveis antes de continuarem com suas atividades',
    'PleaseclickSave': 'Clique em Salvar',
    'Pleasecorrectpageaddress': 'Por favor, corrija o endereço da página ou entre em contato com o suporte se você acredita que este URL está correto',
    'Pleasefillupthefields': 'Preencha os campos',
    'Pleasefillupthefieldsthataremarkedrequired': 'Preencha os campos que são marcados como exigidos',
    'PleasegotoSettings': 'Vá para \'Configurações - Notificações - PSSR Móvel\' para permitir a notificação',
    'Pleaseselectanyoption': 'Selecione qualquer opção que se aplique ao escopo da mudança e modelo',
    'Pleaseselectanyoptionthatapplytothescopeofchangeandtemplate': 'Selecione qualquer opção que se aplique ao escopo da mudança e modelo',
    'Post-WalkdownActionItems': 'Itens de Ação após a inspeção de campo',
    'PostWalkdown': 'Pós-Inspeção de Campo',
    'PreStartupSafetyReview': 'Revisão de Segurança Pré-Inicialização',
    'Present': 'Presente',
    'PrimaryCategory': 'Categoria primária',
    'Print': 'Imprimir',
    'PrinttoPDF': 'Imprimir para PDF',
    'Priority': 'Prioridade',
    'ProcessActivated': 'Processo ativado',
    'Progress': 'Progresso',
    'Project': 'Projeto',
    'ProjectName': 'Nome do Projeto',
    'ProjectTeamWillComplete': 'A equipe do projeto concluirá após a inicialização',
    'Projects': 'Projetos',
    'ProjectsList': 'Lista de Projetos',
    'Projectteamwillcompleteafterstartup': 'O item será concluído após a inicialização',
    'ProvidedBy': 'Fornecido por',
    'PullFromKMS': 'Puxar do KMS',
    'PushNotification': 'Receber notificação push',
    'Question': 'Pergunta',
    'RESET': 'REDEFINIR',
    'ReOpen': 'Reabrir',
    'ReadyToStartUp': 'Pronto para iniciar',
    'ReceivePushNotification': 'Receber notificação push',
    'Receivedfrom': 'Recebido de',
    'Recordwillbepermanentlyremovedcontinue': 'O registro será removido permanentemente, continuar?<br/>',
    'RedirectURL': 'Redirecionar URL',
    'ReferenceImage': 'Imagem de referência',
    'Reject': 'Rejeitar',
    'Rejectionreason': 'Motivo da rejeição',
    'ReleaseDate': 'Data de lançamento',
    'Remove': 'Remover',
    'RemoveLocalData': 'Remover dados locais',
    'RemoveLog': 'Remover Log',
    'RemoveParticipant': 'Remover participante',
    'RemoveRedLining': 'Remover marcações em vermelho',
    'RemoveSection': 'Remover Seção',
    'RemoveSignature': 'Remover assinatura',
    'RemoveYellowLining': 'Remover marcações em amarelo',
    'RemovingWarning': '<i>Itens de ação</i>, <i>comentários</i> e <i>imagens</i> serão <u>removidos</u> desta pergunta',
    'Reopen': 'Reabrir',
    'RequestAccess': 'Solicitar acesso',
    'RequestTranslation': 'Solicitar tradução',
    'RequestedBy': 'Requerido por',
    'RequestedOn': 'Solicitado em',
    'Requestedby': 'Requerido por',
    'RequireActionItemDueDate': 'Exigir data de vencimento do item de ação',
    'RequireFinalApproverBeforeInitiation': 'Exigir Aprovador Final Antes da Iniciação',
    'RequiredField': 'Este campo é obrigatório.',
    'RequiresApprovalForAccess': 'Requer aprovação para acesso',
    'RequiresShowProject': '*Requires Show Project to be enabled.',
    'ResetABChecklistsforEntireProject': 'Redefinir listas de verificação AB para todo o projeto',
    'ResetABChecklist': 'Redefinir lista de verificação AB',
    'ResetData': 'Redefinir dados',
    'ResetSession': 'Redefinir sessão',
    'ResolutionImages': 'Imagens de resolução',
    'RestorePssr': 'Restaurar PSSR',
    'RestrictApproverSignatureOnLeadsDevice': 'Restringir a assinatura do aprovador no dispositivo de líderes',
    'ReturnTeamLead': 'Retornar ao líder de equipe',
    'ReviewInProgress': 'Revisão em andamento',
    'Role': 'Função',
    'RolesChangeWarning': 'Funções e alterações serão refletidas após a sincronização',
    'Russian': 'Russo',
    'RussianTranslationRequested': 'Tradução para o russo solicitada',
    'SAVESIGNATURE': 'SALVAR ASSINATURA',
    'Save': 'Salvar',
    'SaveChanges': 'Salvar alterações',
    'SaveGoBack': 'Salvar e voltar',
    'SaveImage': 'Salvar imagem',
    'SaveSignature': 'SALVAR ASSINATURA',
    'SavingFile': 'Salvando arquivo',
    'SavingImage': 'Salvando imagem',
    'ScheduledEmail': 'Acionar e-mail agendado (todas as segundas-feiras)',
    'Screen': 'Tela',
    'SearchMOCNumber': 'Pesquisar número MOC',
    'SecondaryCategory': 'Categoria Secundária',
    'SectionType': 'Tipo de Seção',
    'SectionLead': 'Líder de Seção',
    'Sections': 'Sessões',
    'ShowSectionsCollapsedInstruction': '*If checked PSSR sections will be displayed collapsed when editing',
    'Select': 'Selecionar',
    'SelectFileError': 'Erro ao selecionar arquivo',
    'SelectFromKMSMOCList': 'Selecionar da lista de MOC do KMS',
    'SelectLead': 'Selecionar Líder',
    'SelectOption': 'Selecionar Opção',
    'SelectParticipant': 'Selecionar participante',
    'SelectResponse': 'Selecionar resposta',
    'SelectaDrawing': 'Selecione um desenho',
    'SelectfromQAQC': 'Selecionar de QAQC',
    'Selectpages': 'Selecionar páginas',
    'Selectthepagestoconverttoimages': 'Selecione as páginas para converter em imagens',
    'Selectuserfor': 'Selecionar usuário para',
    'SelectUser': 'Selecione o usuário',
    'Send': 'Enviar',
    'SendAllNotificationsToApprovers': 'Aprovadores recebem todas as notificações de PSSR',
    'SendEmailReminder': 'Enviar lembrete de e-mail',
    'SendMOCforStartup': 'Send to MOC record for startup approval',
    'SerialNumber': 'Número de série',
    'SerialNumberRequired': 'Número de série obrigatório',
    'Settings': 'Definições',
    'Shared': 'Compartilhado',
    'Shipment': 'Remessa',
    'ShouldAddCommentToQuestion': 'Exigir comentário para pergunta NA',
    'Show': 'Mostrar',
    'ShowCCC': 'Mostrar CCC',
    'ShowDeletedPSSR': 'Mostrar PSSR excluída',
    'ShowKMS': 'Mostrar KMS',
    'ShowORR': 'Mostrar ORR',
    'ShowP5': 'Mostrar P5',
    'ShowProject': 'Mostrar projeto',
    'ShowProjectInput': 'Mostrar entrada de projeto',
    'ShowProjectInputAsDropdown': 'Mostrar entrada do projeto como lista suspensa',
    'ShowPssrType': 'Mostrar tipo de PSSR',
    'ShowQAQC': 'Mostrar QAQC',
    'ShowSectionsCollapsed': 'Mostrar seções recolhidas',
    'ShowVerbalApproval': 'Mostrar aprovação verbal',
    'SignatoryName': 'Nome do signatário',
    'Signature': 'Assinatura',
    'SignatureCapture': 'Capturar assinatura',
    'Signatures': 'Assinaturas',
    'SignedBy': 'Assinado por',
    'SignedOnBehalfOf': 'Assinado em nome de',
    'Skidded': 'Com Skid',
    'SortBy': 'Classificar por',
    'SortChangesApplied': 'As alterações de classificação serão aplicadas após pressionar o botão Salvar',
    'SortOrder': 'Ordem de classificação de EAM',
    'Startaddingparticipants': 'Começar a adicionar participantes',
    'Started': 'Iniciado',
    'Status': 'Situação',
    'StatusIsRequired': 'Campo Status é obrigatório',
    'SubArea': 'Subárea',
    'SubCategories': 'Subcategorias',
    'SubSection': 'Sub Sessão',
    'Subcategories': 'Subcategorias',
    'Submit': 'Enviar',
    'SubmitforApproval': 'Enviar para aprovação',
    'Submitforapproval': 'Enviar para aprovação',
    'SubsectionDescription': 'Descrição da Subseção',
    'Summary': 'Resumo',
    'SummarySignature': 'Assinatura de Resumo',
    'Sync': 'Sincronizar',
    'SystemAdministrators': 'Administradores do Sistema',
    'TEAMMEMBERS': 'MEMBROS DA EQUIPE',
    'Tag': 'Etiqueta',
    'TagCode': 'Código de etiqueta',
    'TagNo': 'Nº da etiqueta',
    'Takeaphoto': 'Tirar foto',
    'Tank': 'Tanque',
    'TaskPlanSections': 'Seções do Plano de Tarefas',
    'TaskPlan': 'Plano de Tarefas',
    'TaskPlanNoteHeader': '[Nota]&nbsp;O nome do código precisa ser exclusivo e não pode ser renomeado após a criação do Plano de Tarefas',
    'TeamLead': 'Líder de Equipe',
    'Template': 'Modelo',
    'Test': 'Teste',
    'TestforDeleteDBwithoutApprover': 'Teste para excluir DB sem aprovador',
    'TheTaskPlanfieldisrequired': 'O campo Plano de Tarefas é obrigatório',
    'Thereareexistingitemsusingit': 'Ele está em uso por itens existentes',
    'Therewasanerrorprocessingtryagain': 'Houve um erro ao processar seu pedido. Tente novamente mais tarde ou entre em contato com o suporte técnico.',
    'Thisfieldisrequired': 'Este campo é obrigatório.',
    'Thisisnotareversiblechange': 'Esta não é uma mudança reversível',
    'TimeCaptured': 'Hora de captura',
    'Title': 'Título',
    'Titleisrequired': 'O título é necessário',
    'TotalQuestions': 'Total de perguntas',
    'TranslationCompleted': 'Tradução concluída',
    'TranslationHistory': 'Histórico de traduções',
    'TranslationInProgress': 'Tradução em andamento',
    'TranslationRequested': 'Tradução solicitada',
    'Translator': 'Tradutor',
    'Type': 'Tipo',
    'TypeOtherisonlyfornon': 'O tipo \'Outro\' é apenas para usuários que não são da Chevron. O uso desse tipo pode causar dados de usuário duplicados',
    'Undo': 'Desfazer',
    'UndoImageError': 'Erro ao desfazer imagem',
    'Unit': 'Unidade',
    'Update': 'Atualizar',
    'UpdatedBy': 'Atualizado por',
    'UpdatedByUpdatedDate': 'Data de atualização',
    'UpdatedDate': 'Data de atualização',
    'Upload': 'Upload',
    'UploadDocError': 'Erro ao carregar documento',
    'UploadDocErrorMsg': 'Não foi possível salvar documento',
    'UploadDocument': 'Carregar documento',
    'UploadDocuments': 'Carregar documentos',
    'UploadImages': 'Carregar imagens',
    'UploadMsg': 'O novo documento foi salvo',
    'Uploadaphoto': 'Carregar uma foto',
    'User': 'Usuário',
    'User1': 'Usuário 1',
    'User2': 'Usuário 2',
    'User3': 'Usuário 3',
    'User4': 'Usuário 4',
    'UserAlreadyAdded': 'Usuário já adicionado',
    'UserType': 'Tipo de Usuário',
    'Users': 'Usuários',
    'Usersavedsuccessfully': 'Usuário salvo com sucesso',
    'Vendor': 'Fornecedor',
    'VerbalApproval': 'Aprovação verbal',
    'VerbalApprovalReceivedBy': 'Aprovação verbal recebida por',
    'VerbalApprovalforStartupOn': 'Aprovação verbal para inicialização em',
    'Version': 'Versão',
    'ViewImage': 'Visualizar imagem',
    'ViewItems': 'Visualizar itens',
    'WO': 'WO',
    'WTNotPresentParticipantsWarning': 'Os seguintes participantes não foram selecionados como presentes',
    'WTParticipantsWithoutSignatureWarning': 'Os seguintes participantes não têm assinatura',
    'WTRestrictParticipantsModificationWarning': 'Após a conclusão da inspeção de campo, os participantes não serão modificáveis',
    'Walkdown': 'inspeção de campo',
    'WalkdownSignature': 'Assinatura da inspeção de campo',
    'WalkdownComplete': 'Validação em campo concluída',
    'WalkdownCompletedOn': 'Inspeção de campo concluída em',
    'Warning': 'Atenção',
    'WebLink': 'Link da Web',
    'WorkOrder': 'Ordem de serviço QAQC',
    'WorkOrders': 'Ordens de serviço QAQC',
    'WorkOrdersNoavailable': 'Não há marcos/ordens de serviço disponíveis para QAQC',
    'XCoordinate': 'Coordenada X',
    'YCoordinate': 'Coordenada Y',
    'Yes': 'Sim',
    'Youareabouttologout': 'Você está prestes a sair. Isso removerá os dados locais neste dispositivo. Deseja continuar?',
    'Youareabouttorequestaccessfor': 'Você está prestes a solicitar acesso para',
    'Youareloggedinas': 'Conectado como\t',
    'Youdonothaveaccesstothispage': 'não tenho acesso ao aplicativo de PSSR',
    'Yourprofile': 'Seu perfil',
    'approvethisPSSRreadyforstartup': 'aprovar esta PSSR pronta para inicialização',
    'cannotberemoved': 'não pode ser removido',
    'cost255': 'Código de custo (máximo de 255 caracteres)',
    'desc5000': 'Adicione descrição aqui (máximo de 5.000 caracteres)',
    'description1920': 'Adicione descrição aqui (máximo de 1920 caracteres)',
    'description500': 'Adicione descrição aqui (máximo de 500 caracteres)',
    'device150': 'Nome do tipo de dispositivo (máximo de 150 caracteres)',
    'donothaveaccesstothePSSRapplication': 'não tem acesso ao aplicativo de PSSR',
    'eam50': 'Tipo de EAM (máximo de 50 caracteres)',
    'eamclass80': 'Classe de EAM (máximo de 80 caracteres)',
    'eamcritical50': 'Criticidade de EAM (máximo de 50 caracteres)',
    'eamdep255': 'Departamento de EAM (máximo de 255 caracteres)',
    'eamdesc80': 'Descrição de EAM (máximo de 80 caracteres)',
    'eamname80': 'Nome de EAM (máximo de 80 caracteres)',
    'fromTaskPlan': 'do Plano de Tarefas',
    'fromTaskPlanSection': 'da Seção do Plano de Tarefas',
    'group155': 'Nome do grupo (máximo de 155 caracteres)',
    'isrequired': 'é necessário',
    'list': 'lista',
    'manufacturer250': 'Nome do fabricante (máximo de 250 caracteres)',
    'model250': 'Modelo (máximo de 250 caracteres)',
    'noticetousers': 'aviso aos usuários',
    'of10': 'de 10',
    'of150': 'de 150',
    'of155': 'de 155',
    'of250': 'de 250',
    'of255': 'de 255',
    'of50': 'de 50',
    'of500': 'de 500',
    'of80': 'de 80',
    'optional': 'opcional',
    'select1milestone': 'Selecione 1 marco para visualizar as ordens de serviço dentro desse marco',
    'tagcode10': 'Código de Etiqueta (máximo de 10 caracteres)',
    'tagno10': 'Número da etiqueta (máximo de 10 caracteres)',
    'typeemail': 'digite novo e-mail de usuário...',
    'typelastname': 'digite o sobrenome do novo usuário...',
    'typenewuser': 'digite o nome do novo usuário...',
    'typeusercainame': 'digite CAI de usuário da Chevron, nome ou e-mail...',
    'willberemoved': 'será removido(a)',
    'willbeungrouped': 'Caso existam alguns Equipamentos associados a este Grupo, estes serão desagrupados',
    'x255': 'Coordenada X (máximo de 255 caracteres)',
    'y255': 'Coordenada Y (máximo de 255 caracteres)',
    'yourAccessRequestisinProgress': 'sua solicitação de acesso está em andamento',
    'youwanttoproceed': 'você deseja continuar',
    'Unabletocompleteoperation': 'Unable to complete operation',
    'SaveCompleted': 'Save Completed',
    'Itemhasbeenaddedsuccessfully': 'Item has been added successfully',
    'ShowProjectInputInstruction': '*Displays project dropdown/input',
    'ShowQAQCInstruction': '*Enables QAQC Module at project module. Requires Show Project to be enabled first.',
    'ShowKMSInstruction': '*Displays "Pull from KMS" button when you are creating a new PSSR',
    'AllowWetSignatureInstruction': '*Allows Operations Representative to approve the PSSR at "Pending approval" status. Displays button "Approve by wet signature"',
    'ShowVerbalApprovalInstruction': '*Display an input in Mobile App to manually write who is approving. This doesn\'t cause any action.Can be performed by Lead or Approver',
    'ScheduledEmailInstruction': '*Each Monday at 8:00 am CST an email is triggered for all the users with pending action items',
    'ShowCCCInstruction': '*Displays "CCC" at some headers/titles/labels (Construction Complete Certificate)',
    'ShowORRInstruction': '*Displays "ORR" at some headers/titles/labels (Operation Readines Review)',
    'ShowPssrTypeInstruction': '*Displays "PSSR Types" dropdown (below)',
    'AllowNAResponsesInstruction': '*If Disabled: a question at PSSR that is answered as NA would require a comment or action item to be considered as completed.',
    'PssrInstructionsInstruction': '*Displays general instructions for the PSSR',
    'AllowLeadsDeleteQuestionsInstruction': '*Allows Leads to delete questions for a PSSR on status Draft or Initiated.',
    'EnableAllowMembersCompletePSSRInstruction': ' *Allows any participant to complete a PSSR before it is Completed (answer questions, sign questions, create action items)',
    'MOCWOProjectNumberRestrictionEnabledInstruction': '*If Enabled: MOC and WO are required to create a PSSR',
    'AllowMultiApproverInstruction': '*If Enabled: PSSR can have more than one Approver',
    'RestrictApproverSignatureOnLeadsDeviceInstruction': '*If Enabled: PSSR Lead cannot sign in behalf of the Approver in Mobile App',
    'AllowLeadtoShareDeviceWithApprover': 'Allow Lead to Share Device With Approver',
    'AllowLeadtoShareDeviceWithApproverInstruction': '*If Enabled: The Lead can share the device with the Approver if the Approver is present to sign.',
    'RequiresApprovalForAccessInstruction': '*If this flag is Enabled the user will wait until BU Admin grants access. If disabled, user will get instant access by selecting this BU',
    'AllowApproverToSubmitForApprovalInstruction': '*If Checked Final Approval will be able to see Submit for Approval button in PSSR',
    'OrganizationCannotBeEmpty': '{{OrganizationLabel}} cannot be empty',
    'OrganizationSuccessfullyAdded': '{{OrganizationLabel}} Successfully added',
    'MOCImportedSuccessfully': 'MOC Imported Successfully',
    'GeneralInformationSavedSuccessfully': 'General Information Saved Successfully',
    'Therecordenteredalreadyexists': 'The record entered already exists',
    'SelectuserforTranslationRole': 'Select user for Translation Role',
    'TranslatetoLanguage': 'Translate to Language',
    'Selecttranslator': 'Select translator',
    'PresentParticipantsInstruction': 'Participantes em <span class="box participated">&nbsp;Azul&nbsp;</span> participaram da inspeção de campo',
    'PssrParticipantAddedSuccessMessage': 'User added to PSSR participants successfully',
    'RoleAlreadyAddedWarningMessage': '{{RoleLabel}} Role is already added',
    'MaxParticipantsPerBatchInstruction': 'Only 5 users can be added at a time',
    'MaxParticipantsPerBatchWarningMessage': '5 user limit reached, please select add',
    'ParticipantAlreadyParticipatedWarningMessage': 'This user is already a member of this pssr',
    'ManageSectionUsersSyncWarning': 'This section is not yet visible on mobile until user syncs. Please notify participants to sync their mobile devices before continuing with their activities.',
    'NotFoundComponentTitle': 'Not found',
    'NotFoundComponentDescription': 'Please correct page address or contact support if you believe this url is correct',
    'UnauthorizedTitle': 'Not authorized',
    'UnauthorizedDescription': 'You do not have access to this page.',
    'BUisrequired': 'BU is required',
    'SectionLeadWasAlreadyAddedWarningMessage': '{{UserName}} is already a lead of this section',
    'SectionLeadAddedErrorMessage': 'Error happened while adding user',
    'SectionLeadRemovedErrorMessage': 'Error happened while removing user',
    'AddResponseErrorMessage': 'Error happened while creating response question',
    'ConfirmRemoveSectionTitle': 'Remove Section',
    'ConfirmRemoveSectionDescription': 'Are you sure that you want to perform this action?',
    'MilestoneWorkOrdersWarningMessage': 'Please select 1 milestone to view work orders within that milestone',
    'QAQCAddSectionLeadToPssr': 'Assign section leads',
    'LoadBusinessUnitsErrorMessage': 'Error getting Business Units',
    'LoadOrganizationsErrorMessage': 'Error getting Organizations',
    'AddProjectErrorMessage': 'Error adding new {{EventLabel}}',
    'AddProjectSuccessMessage': 'Project added successfully',
    'LoadQAQCErrorMessage': 'Error getting {{EventLabel}} from QAQC',
    'SaveQAQCAlreadyExistsWarningMessage': '{{EventLabel}} already exists',
    'SaveQAQCSuccessMessage': '{{EventLabel}} added successfully',
    'CompletedTotalPSSRs': 'Completed/Total PSSRs',
    'ProjectUpdatedSucessfully': 'Project updated successfully',
    'ConfirmDeleteProjectTitle': 'Confirm Project Deletion',
    'ConfirmDeleteProjectWithPssrsDescription': 'The following action could delete the Project and the PSSRs involved. Would you like to continue?',
    'ConfirmDeleteProjectWithoutPssrsDescription': 'Would you like to proceed?',
    'ConfirmInitiateProjectPssrsTitle': 'Do you want to initiate All PSSRs?',
    'ConfirmInitiateProjectPssrsDescription': 'Do you want to initiate All PSSRs?',
    'UnableLoadQAQCMilestonesMessage': 'Unable to reach qaqc system for milestones, please try again later',
    'UnableLoadQAQCWorkOrdersMessage': 'Unable to reach qaqc system for workorders, please try again later',
    'LoadProjectDetailsErrorMessage': 'Error loading project details',
    'QAQCItemsAreUpToDateMessage': 'QAQC Items are up to date',
    'QAQCNewItemInputWarning': 'Please select an item from the {{qaqcLabel}} list and select the hierarchy values and/or template',
    'ConfirmCloseProjectTitle': 'Close Project',
    'ConfirmCloseProjectDescription': 'Do you want to close this project?',
    'DownloadActionItemsExcelReportFailureMessage': 'Failed to download the excel file',
    'ConfirmDeleteAreaTitle': 'Tem certeza de que deseja excluir {{hierarchyLabel1}}',
    'ConfirmDeleteAreaDescription': 'All the related {{ hierarchyLabel2 }} & {{ hierarchyLabel3 }} will be removed.',
    'ConfirmDeleteSubAreaTitle': 'Tem certeza de que deseja excluir {{ hierarchyLabel2 }}',
    'ConfirmDeleteSubAreaDescription': 'All the related {{ hierarchyLabel3 }} will be removed',
    'NewGroupadded': 'New Group added',
    'RequiredFields': 'Campos Requeridos',
    'AddedUserSuccessfulMessage': 'User Successfully added',
    'DeletedUserSuccessfulMessage': 'User Successfully deleted',
    'EditedGroupSucessfulMessage': 'Group successfully updated',
    'Userisrequired': 'User is required',
    'FirstNameisrequired': 'First Name is required',
    'SharedUserNameisrequired': 'Shared User Name is required',
    'Emailisrequired': 'Email is required',
    'User1isrequired': 'User 1 is required',
    'Usertypeiswrong': 'User type is wrong',
    'RepeatedEmailsInputWarning': 'These emails are repeated: [{{repeatedEmails}}}]<br/>',
    'Shareduserfound': 'Shared user found',
    'AddRemoveSubcategory': 'Add/Remove Subcategory',
    'CreateNewCategory': 'Create New Category',
    'IsActive': 'Is Active',
    'EditCategory': 'Edit Category',
    'CommentisRequired': 'Comment is Required',
    'RejectreasonisRequired': 'Reject reason is Required<br>',
    'Duedateisrequired': 'Due date is required',
    'IAgree': 'I Agree',
    'All': 'All',
    'CreateFeedback': 'Create Feedback',
    'Enhancement': 'Enhancement',
    'Environment': 'Environment',
    'Web': 'Web',
    'MobileWeb': 'Mobile & Web',
    'Mobile': 'Mobile',
    'High': 'High',
    'Medium': 'Medium',
    'Low': 'Low',
    'FeedbackSuccessfullyadded': 'Feedback Successfully added',
    'EditFeedback': 'Edit Feedback',
    'Typetoaddanewdiscussioncomment': 'Type to add a new discussion comment',
    'Attachments': 'Attachments',
    'ADOStatus': 'ADO Status',
    'FeedbackUpdated': 'Feedback Updated',
    'UpdatedFeedbacksAttachmentErrorMessage': 'An error happened with the files. Please review your files are not corrupt or too big',
    'LoadingKMSItemsErrorMessage': 'An error occurred when searching KMS items',
    'TaskPlanManageTitle': 'Gerenciar conteúdo de {{ Description }}',
    'CreateNewSection': 'Criar novo Section',
    'NewSubsection': 'New Subsection',
    'ItemType': 'Item Type',
    'ConfirmDeleteTaskPlanSectionMessage': 'Are you sure you want to delete\n      <span style="color: red">{{ taskPlanSectionDesc }}</span> <br />\n      from Task Plan {{ taskPlanDesc }} ?',
    'ConfirmDeleteTaskPlanSubSectionMessage': 'Are you sure you want to delete <span style="color:red">{{subSection}}</span> <br> from Task Plan Section <b>{{section}}</b> ?',
    'EditTaskPlanSubSection': 'Edit Task Plan Sub Section',
    'RequestforComments': 'Request for Comments',
    'RequestforActionItems': 'Request for Action Items',
    'PSSRInitializedSuccessfully': 'PSSR Initialized Successfully',
    'PrimeNgCalendarLabels': {
        'firstDayOfWeek': 0,
        'dayNames': [
            'Domingo',
            'Segunda-feira',
            'terça-feira',
            'quarta-feira',
            'quinta-feira',
            'Sexta-feira',
            'sábado'
        ],
        'dayNamesShort': [
            'Sun',
            'Mon',
            'Tue',
            'Wed',
            'Thu',
            'Fri',
            'Sat'
        ],
        'dayNamesMin': [
            'Su',
            'Mo',
            'Tu',
            'We',
            'Th',
            'Fr',
            'Sa'
        ],
        'monthNames': [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'
        ],
        'monthNamesShort': [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ],
        'today': 'o dia',
        'clear': 'Clear',
        'dateFormat': 'mm/dd/yy',
        'weekHeader': 'Wk'
    },
    'Adminmenu': 'Menu',
    'Organizations': 'Organização',
    'UserGuide': 'Guia do Usuário',
    'Administration': 'Administração',
    'ProjectSpecific': 'Projeto Especifico',
    'Showdeletedprojects': 'Mostrar projetos excluídos',
    'ProjectManagers': 'Gerente(s) do Projeto',
    'ProjectTeamMembers': 'Membros do Projeto',
    'Activate': 'Activate',
    'AddActionItem': 'Add Action Item',
    'Attention': 'Attention',
    'BacktoCategoryList': 'Back to Category List',
    'Category': 'Category',
    'CompleteTranslationWarning': 'Please translate PSSR Title, Description, Action Item Title and Action Item Comments',
    'ConfirmTranslationRequest': 'You are about to request a translation in {{language}} to {{email}}',
    'DeselectAll': 'Deselect All',
    'FirstName': 'First Name',
    'Itemsselected': 'Items selected',
    'Language': 'Language',
    'LastName': 'Last Name',
    'MOC#': 'MOC #',
    'New': 'New',
    'Org': 'Org',
    'ShowDeletedPSSRCCC': 'Show Deleted PSSR / CCC',
    'ShowDeletedPSSRCCCORR': 'Show Deleted PSSR / CCC / ORR',
    'ShowDeletedPSSRORR': 'Show Deleted PSSR / ORR',
    'Togglenavigation': 'Toggle navigation',
    'Youshouldnothaveemptysections': 'You should not have empty sections',
    'togglesearch': 'toggle search',
    'GenericErrorTitle': 'Internal Error',
    'GenericErrorDescription': 'There was an error processing your request. Please try again later or contact helpdesk.',
    'Kazakh': 'Cazaque',
    'PSSRmusthaveaFinalApprovertocompleteWalkdown': 'PSSR must have a Final Approver to complete Walkdown',
    'ParticipatedinWalkthrough': 'Participated in Walkthrough',
    'Subsection': 'sub sessão',
    'AccessUntilApprove': 'Se marcado, o novo usuário terá acesso até que o administrador da BU dê aprovação',
    'AllNotifications': 'Se marcado, o Aprovador Final receberá todas as notificações',
    'AreyousureyouwanttoSubCategory': 'Tem certeza de que deseja excluir a subcategoria',
    'DueDateMandatory': 'Se marcado, a Data de vencimento para itens de ação será obrigatória',
    'FinalApproverSubmit': 'Se marcado, a Aprovação Final poderá ver o botão Enviar para Aprovação no PSSR',
    'FinalApproverInitiate': 'Se marcado, o PSSR exigirá um aprovador final para iniciá-lo',
    'SectionsCollapsed': 'Se marcado, as seções PSSR serão exibidas recolhidas durante a edição',
    'Portuguese': 'Português',
    'Dutch': 'Dutch',
    'SysAdmin': 'Sys Admin',
    'ChevronDisclaimer': 'This Chevron software product is for use by authorized users only. To the extent permitted by law, by\n  using this software product you acknowledge and consent to the monitoring, access (including the\n  decryption and inspection of selected encrypted internet\n  traffic), use or disclosure by Chevron of any information generated, received or stored on this\n  system/device and waive any right or expectation of privacy in connection with your use. Unauthorized\n  and/or improper use of this software\n  product in violation of Chevron corporate policies will be not tolerated and may result in disciplinary\n  action, including termination.',
    'ConfirmDeletePSSRTitle': 'Confirmar exclusão',
    'ConfirmDeletePSSRMessage': 'O PSSR será removido permanentemente, continuar?<br/>',
    'ConfirmNewTrainingTitle': 'Confirm new PSSR Training',
    'ConfirmNewTrainingMessage': 'New Training PSSRs will be created.<br/> Do you want to continue?<br/>',
    'ConfirmDeleteActionItemMessage': 'Tem certeza de que deseja realizar esta ação?',
    'CannotCloseProjectDueOpenPSSRs': 'Não é possível fechar o projeto porque existem PSSRs abertos',
    'PSSRIdisrequired': 'O ID do PSSR é obrigatório',
    'ImportFromisrequired': 'Importar de é obrigatório.',
    'MOCIDisalreadypresentintheImportlist': 'MOC ID já está presente na lista de importação.',
    'ErrorhappenedwhileimportingPSSR': 'Ocorreu um erro ao importar o PSSR',
    'RestoreProject': 'Restaurar Projeto',
    'Spanish': 'Spanish',
    'ImportPSSRs': 'Importar PSSRs',
    'Import': 'Importar',
    'Application': 'Inscrição',
    'Records': 'Registros',
    'Imported': 'Importado',
    'Errors': 'Erros',
    'ImportAll': 'Importar Tudo',
    'QAQCImport': 'QAQC Import',
    'Start': 'Start PSSR',
    'ePSSRServiceNowTicketingInstructions': 'Instruções de emissão de bilhetes ePSSR Service Now',
    'ReportanIssue': 'Informar um problema',
    'EnhancementRequest': 'Solicitação de aprimoramento',
    'UserAccessRequest': 'Solicitação de acesso do usuário',
    'ApplicationSupportRequestForm': 'Acesse o <a href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981" target="_blank">Formulário de solicitação de suporte de aplicativos</a> no Portal de serviços de TI da Chevron',
    'UndertheApplicationFieldselectMobilePSSR': 'No Campo de Aplicação, selecione <b>PSSR Móvel</b>.',
    'ToreportanissueorbugpleaseselectReportanIssueTosubmitanenhancementrequestoruseraccessrequestpleaseselectServiceRequestHitNext': 'Para relatar um problema ou bug, selecione <b>Relatar um problema.</b> Para enviar uma solicitação de aprimoramento ou usuário solicitação de acesso, selecione <b>Solicitação de serviço</b>. Clique em Avançar.',
    'FillouttheDescriptionoftheProblemImpactandUrgency': 'Preencha a Descrição do Problema, Impacto e Urgência.',
    'PressNextandthenSubmit': 'Pressione Avançar e, em seguida, Enviar.',
    'TheePSSRSupportTeamwillacknowledgethereceiptoftheticketin24hoursduringbusinesshours': 'A equipe de suporte ePSSR acusará o recebimento do ticket em 24 horas durante o horário comercial.',
    'PleaseselectEnhancementsunderTypeofRequests': 'Selecione <b>Aprimoramentos</b> em Tipo de Solicitação.',
    'SelectPriorityLowModerateHigh': 'Selecione Prioridade <b>(Baixa, Moderada, Alta)</b>',
    'FillouttheDescriptionofRequest': 'Preencha a Descrição do Pedido',
    'SelectNextandSubmit': 'Selecione Avançar e Enviar.',
    'TheePSSRSupportTeamwillreviewenhancementrequestsaspartoftheSurfaceDigitalPIPlanningProcess': 'A equipe de suporte ePSSR revisará as solicitações de aprimoramento como parte do processo de planejamento do Surface Digital PI',
    'PleaseselectRequestAccessunderTypeofRequests': 'Selecione Solicitar acesso em Tipo de solicitações.',
    'SelectAccessRequestTypeAddorRemove': 'Selecione o tipo de solicitação de acesso <b>(adicionar ou remover)</b>',
    'SpecifyRoleandAccesstheUserneeds': 'Especifique a função e o acesso que o usuário precisa.',
    'UserAlreadyPartipatedInPssr': 'Participant ["{{newTranslator}}"] has already participated in pssr',
    'TranslateActionItem': 'Translate Action Item',
    'AbandonedPSSRInterval': 'Abandoned PSSR Interval',
    'ShowOnlyDeletedPSSRs': 'Show Only Deleted PSSRs',
    'ShowOnlyAbandonedPSSRs': 'Show Only Abandoned PSSRs',
    'CloseConfirmation': 'Remember, by closing the PSSR, you confirm that all action items have been closed. Close PSSR?',
    'ReimportAllSharedUsers': 'Re-import All Shared Users',
    'ImportSharedUsersFailed': 'Import failed',
    'ImportSharedUsersIncompleted': 'Import incomplete',
    'ImportSharedUsersCompleted': 'Import complete in: {{ minutes }}mins',
    'ConfirmReimportAllSharedUsersHeader': 'Re-import All Shared Users',
    'ConfirmReimportAllSharedUsersMessage': 'You are about to update all shared users configuration.<br> This operation could take several minutes to complete.',
    'SendaremindertoAssignees': 'Send a reminder to Assignees',
    'SendReminderInstructions': 'Send reminder to assignees for all opened AIs.\nWhen PSSR is in Pending Approval status, \nApprovers will receive notification.',
    'Sendamessage': 'Send a message',
    'SendanemailmessagetoallPSSRParticipants': 'Send an email message to all PSSR Participants',
    'ImportSharedUserOptionWhitePagesTitle': 'White Pages Integration',
    'ImportSharedUserOptionWhitePagesDescription': 'Use this type of shared email if you need to sync with White Pages. The list of users will be updated automatically over time. Some users may be automatically added or removed from this shared email.',
    'ImportSharedUserOptionCustomTitle': 'Custom Shared Email',
    'ImportSharedUserOptionCustomDescription': 'Manually manage the list of users.',
    'ImportActionItemsSuccessfully': 'Imported Action Items successfully',
    'ImportActionItemsNone': 'No Action Items to import',
    'FR.Titles.FunctionalReview': 'Revisão funcional',
    'FR.Actions.CompleteReview': 'Complete Review',
    'FR.Warnings.NotFoundFunctionalReview': 'There is no active functional review for selected filters. To start new functional review click create button.',
    'FR.Warnings.CannotStartFunctionalReview': 'Please select an asset and review type to start a functional review',
    'FR.Titles.ChecklistSections': 'Seções do Plano de Tarefas',
    'FR.Titles.CreateNewSection': 'Criar novo Section',
    'FR.Titles.CreateNewSubSection': 'Criar novo Sub Sessão',
    'FR.Titles.ManageChecklist': 'Gerenciar conteúdo de {{ Description }}',
    'FR.Forms.Labels.ReviewType': 'Tipo de revisão',
    'FR.Forms.Labels.Description': 'Descrição',
    'FR.Tables.Headers.ID': 'ID',
    'FR.Tables.Headers.Priority': 'Prioridade',
    'FR.Tables.Headers.Action': 'Ação',
    'FR.Tables.Headers.Comment': 'Comentário',
    'FR.Tables.Headers.UpdatedBy': 'Atualizado por',
    'FR.Tables.Headers.Answer': 'Resposta',
    'FR.Tables.Headers.Question': 'Pergunta',
    'FR.Tables.Headers.Code': 'Código',
    'FR.Tables.Headers.ReviewType': 'Tipo de revisão',
    'FR.Tables.Headers.Description': 'Descrição',
    'FR.Actions.CreateNewItem': 'Criar novo item',
    'FR.Actions.BackToChecklists': 'Back to Checklists',
    'FR.Titles.CreateChecklistItem': 'Create a Checklist Item',
    'FR.Actions.BackToChecklistsManage': 'Back to Checklist Manage',
    'FR.Titles.EditChecklistItem': 'Edit Checklist Item',
    'FR.Titles.ConfigureActionItem': 'Configurar Item de Ação',
    'FR.Titles.ConfigureComment': 'Configure Comment',
    'FR.Forms.Labels.Action': 'Ação',
    'FR.Forms.Labels.SubSection': 'Sub Sessão',
    'FR.Forms.Labels.Comment': 'Comentário',
    'FR.Forms.Labels.Priority': 'Prioridade',
    'FR.Forms.Errors.InputRequired': 'Este campo é obrigatório.',
    'FR.Forms.Errors.RepeatedReviewType': 'This review type has already been taken',
    'FR.Forms.Errors.ReviewTypeMaxLength': 'Review Type should be {{maxlength?.requiredLength}} characters only',
    'FR.Titles.CreateChecklist': 'Create a Checklist',
    'FR.SubTitles.ReviewTypeWarning': '[Note]:&nbsp;Review Type needs to be unique, and it cannot be renamed after Checklist is created.',
    'FR.Errors.UnavailableMOC': 'Please use the MOC tool to access the Functional Review.',
    'FR.Errors.BuAssetNotSelected': 'Please select a Business Unit and Asset',
    'FR.Errors.ReviewError': 'An error occurred while completing the review',
    'FR.Forms.Labels.SectionDescription': 'Descrição da Seção',
    'FR.Forms.Labels.SubsectionDescription': 'Descrição da Subseção',
    'FR.Messages.CommentAdded': 'Comment added!',
    'FR.Messages.CommentUpdated': 'Comment updated!',
    'FR.Messages.CommentDeleted': 'Comment deleted!',
    'FR.Messages.ActionItemAdded': 'Action item added!',
    'FR.Messages.ActionItemUpdated': 'Action item updated!',
    'FR.Messages.ActionItemDeleted': 'Action item deleted!',
    'FR.Messages.ResponseAdded': 'Response added!',
    'FR.Messages.ResponseAnswered': 'Response answered!',
    'FR.Messages.ResponseRemoved': 'Response removed!',
    'FR.Messages.ChecklistCreated': 'Check list created',
    'FR.Messages.ChecklistUpdated': 'Check list updated',
    'FR.Messages.ChecklistSectionUpdated': 'Check list section updated',
    'FR.Messages.ChecklistSubSectionUpdated': 'Check list sub section updated',
    'FR.Messages.ChecklistItemCreated': 'Check list item created',
    'FR.Messages.ChecklistItemUpdated': 'Check list item updated',
    'FR.Actions.Add': 'Adicionar',
    'FR.Actions.Create': 'Criar',
    'FR.Actions.Save': 'Salvar',
    'FR.Actions.Cancel': 'Cancelar',
    'FR.Messages.ReviewCompleted': 'Review completed successfully',
    'FR.Dialogs.Actions.Yes': 'Sim',
    'FR.Dialogs.Actions.No': 'Não',
    'FR.Dialogs.Title.Delete': 'Confirmar exclusão',
    'FR.Dialogs.Description.Delete': 'Would you like to delete?',
    'FR.Titles.EditChecklistSection': 'Edit a Checklist Section',
    'FR.Titles.EditChecklistSubSection': 'Edit a Checklist Subsection',
    'FR.Forms.Labels.Code': 'Código',
    'FR.Forms.Labels.CreatedBy': 'Criado por',
    'FR.Forms.Labels.UpdatedDate': 'Data de atualização',
    'FR.Forms.Labels.UpdatedBy': 'Atualizado por',
    'FR.Forms.Labels.ShouldAddCommentToQuestion': 'Exigir comentário para pergunta NA',
    'FR.Titles.LastModification': 'Last Modification',
    'FR.Titles.Checklist': 'Checklists',
    'FR.Filters.BusinessUnit': 'Unidade de Negócios',
    'FR.Filters.TaskPlan': 'Plano de Tarefas',
    'FR.Actions.CreateNewChecklist': 'Criar novo',
    'FR.Actions.Edit': 'Editar',
    'FR.Actions.Manage': 'Gerenciar',
    'FR.Actions.Delete': 'Excluir',
    'FR.Titles.MOC': 'MOC',
    'FR.Titles.EditChecklist': 'Edit a Checklist',
    'Alerts.OutdatedApp.Message': 'Observe que o aplicativo móvel atual será desativado em breve. Incentivamos todos os usuários a atualizarem para o novo aplicativo móvel \'PSSR 3\' o mais breve possível.'
};
